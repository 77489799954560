.collection-section .ant-card-meta {
  text-align: center;
  justify-content: center;
}

.collection-section {
  margin-top: 50px;
}

.collection-divider {
  margin-top: 37px;
  margin-bottom: 50px;
  border-top: 0.5px solid #aaaaaa;
}

/* Filter Slider start */
.collection-section .slick-list {
  max-width: 1200px;
  margin: 0 auto;
}

.collection-filter-slider__item {
  /* max-width: 110px; */
  overflow: hidden;
}

.collection-filter-slider__item p {
  width: 100%;
  text-align: center;
  font-size: 12px;
}

.icon-wrapper {
  position: absolute;
  z-index: 99;
  top: 38%;
  transform: translate(0, -50%);
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 50px;
  max-height: 50px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #707070;
  background: white;
  font-size: 30px;
}

.on-right {
  right: 0;
}

.on-left {
  left: 0;
}

.slick-prev::before,
.slick-next::before {
  opacity: 0;
  display: none;
}

.img-wrapper {
  margin: 0 auto;
  max-width: 110px;
  max-height: 110px;
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* border: 1px solid #707070; */
  margin-bottom: 19px;
  cursor: pointer;
}

.img-wrapper:hover,
.img-wrapper-focused {
  border: 1px solid #707070;
}

.img-wrapper-selected {
  border: 1px solid #707070;
}

.collection-filter-slider__item img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 auto;
}
/* Filter Slider end */

.collection-row {
  /* align-items: flex-start; */
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ProductGrid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  column-gap: 30px;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  /* grid-gap: 20px; */
}

.product-card-link {
  max-width: 300px;
  margin: 0 auto;
}

.collection-section .ant-empty-image {
  height: 400px;
}

.collection-section .ant-card-body {
  padding: 24px 15px;
}

.collection-section .ant-layout-sider-collapsed {
  /* visibility: hidden; */
  /* opacity: 0; */
  display: none;
  transition: 0.3s ease-in-out;
}

.collection-row,
.collapsed-btn-row {
  width: 100%;
}

.collapsed-btn-row {
  height: 30px;
}

.mobile-search-bar-row {
  margin-bottom: 50px;
}

.mobile-search-bar-row .ant-col {
  display: flex;
}

.mobile-search-bar-row input {
  justify-content: center;
  align-items: center;
}

.mobile-search-bar-row .ant-input {
  margin: 0 auto;
}

.collapsed-btn-row {
  margin-bottom: 30px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-right: 80px;
}

.collapsed-btn-row .trigger {
  display: inline-block;
  font-size: 20.8px;
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  /* margin: auto 0; */
  /* height: 100%; */
}

.collapsed-btn-row .trigger span:first-child {
  margin-right: 10px;
}

.collection-filter-form .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}

.collection-filter-search {
  width: 80%;
}

.collection-filter-search .ant-input:hover {
  box-shadow: none;
}

/* .collection-section .ant-card img { */
/* .collection-section .ant-card-cover {
  height: 400px;
  width: auto;
  margin: 0 auto;
} */

.collection-section .ant-card img {
  /* height: auto; */
  width: 100%;
  object-fit: cover;
}

.collection-section-layout
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.collection-section-layout .ant-input-affix-wrapper:focus,
.collection-section-layout .ant-input-affix-wrapper-focused {
  border-color: var(--point-color);
  box-shadow: 0 0 0 2px var(--point-shadow-color);
}

.collection-filter-search__input {
  border-radius: 5px;
}

.collection-section-layout .ant-input:hover {
  border: none;
  box-shadow: none;
}

@media all and (min-width: 1920px) {
  .ProductGrid {
    grid-template-columns: repeat(4, minmax(300px, 1fr));
  }
}
@media all and (min-width: 1472px) and (max-width: 1919.98px) {
  .ProductGrid {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }
}

@media all and (min-width: 1024px) and (max-width: 1471.98px) {
  .ProductGrid {
    grid-template-columns: repeat(3, minmax(200px, auto));
  }
}

@media all and (max-width: 800px) {
  .collection-section {
    margin-top: 10px !important;
  }

  .img-wrapper {
    margin-bottom: 2px;
  }

  .collection-divider {
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

@media all and (min-width: 768px) and (max-width: 1023.98px) {
  .ProductGrid {
    grid-template-columns: repeat(3, minmax(130px, auto));
    grid-gap: 20px;
  }
  .collection-section .ant-empty-image {
    height: 300px;
  }
  .collection-section-pd {
    padding-left: 50px;
    padding-right: 50px;
  }

  .collapsed-btn-row {
    padding-right: 50px;
  }

  .collection-filter {
    flex: 0 0 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    width: 200px !important;
  }

  .collection-filter-search {
    width: 70%;
  }

  .collection-section .ant-card-meta-title {
    font-size: 16px;
  }

  .collapsed-btn-row .trigger {
    font-size: 16px;
  }
  .collection-filter-checkbox > label {
    font-size: 14.4px;
  }

  /* .collection-section .ant-input {
    height: 30px;
  } */
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
  .ProductGrid {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 10px;
  }
  .collection-section .ant-empty-image {
    height: 250px;
  }
  .collection-section-pd {
    padding-left: 40px;
    padding-right: 40px;
  }

  .collapsed-btn-row {
    padding-right: 40px;
  }

  .collection-filter-search {
    width: 100%;
  }

  .collapsed-btn-row .trigger {
    font-size: 16px;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .ProductGrid {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 10px;
  }
  .collection-section .ant-empty-image {
    height: 250px;
  }
  .collection-section {
    margin-top: 50px;
  }
  .collection-section-pd {
    padding-left: 35px;
    padding-right: 35px;
  }

  .collapsed-btn-row {
    padding-right: 50px;
  }

  .collection-filter-search {
    width: 100%;
  }

  .collection-section .ant-card-meta-title {
    font-size: 14.4px;
  }

  .collection-section .ant-card-meta-description {
    font-size: 9.6px;
  }

  .collapsed-btn-row .trigger {
    font-size: 14.4px;
  }

  .collection-filter-checkbox > label,
  .collection-section .ant-collapse-header-text {
    font-size: 11.2px;
  }
  /* .collection-section .ant-input {
    height: 28px;
  } */
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 360px) and (max-width: 559.98px) {
  .ProductGrid {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-gap: 10px;
  }
  .collection-section .ant-empty-image {
    height: 100px;
  }
  .collection-section {
    margin-top: 40px;
  }
  .collection-section-pd {
    padding-left: 30px;
    padding-right: 30px;
  }

  .collapsed-btn-row {
    padding-right: 40px;
  }

  .collection-filter-search {
    width: 100%;
  }

  .collection-section .ant-card-meta-title {
    font-size: 11.2px;
  }

  .collection-section .ant-card-meta-description {
    font-size: 9.6px;
  }

  .collapsed-btn-row .trigger {
    font-size: 14.4px;
  }

  .collection-filter-checkbox > label,
  .collection-section .ant-collapse-header-text {
    font-size: 11.2px;
  }

  .collection-divider {
    margin-top: 0;
  }
}
@media all and (max-width: 359.98px) {
  .ProductGrid {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-gap: 5px;
  }
  .collection-section .ant-empty-image {
    height: 100px;
  }
  .collection-section {
    margin-top: 30px;
  }

  .collection-section-pd {
    padding-left: 30px;
    padding-right: 30px;
  }

  .collection-divider {
    margin-top: 10px;
  }

  .collapsed-btn-row {
    padding-right: 40px;
  }

  .collection-filter-search {
    width: 100%;
  }

  .collection-section .ant-card-meta-title {
    font-size: 11.2px;
  }

  .collection-section .ant-card-meta-description {
    font-size: 9.6px;
  }

  .collapsed-btn-row .trigger {
    font-size: 14.4px;
  }

  .collection-filter-checkbox > label,
  .collection-section .ant-collapse-header-text {
    font-size: 11.2px;
  }
  /* .collection-section .ant-input {
    height: 25px;
  } */
  .collection-section .ant-card-body {
    padding: 15px 5px;
  }
}

/* responsive for slick */
@media all and (min-width: 1191px) and (max-width: 1280.98px) {
  .collection-section .slick-list {
    max-width: 900px;
  }
  .collection-filter-slider__item {
    margin-left: 20px;
    margin-top: 3px;
  }
}

@media all and (min-width: 1081px) and (max-width: 1190.98px) {
  .collection-section .slick-list {
    max-width: 800px;
  }
  .collection-filter-slider__item {
    margin-left: 20px;
    margin-top: 3px;
  }
}

@media all and (min-width: 891px) and (max-width: 1080.98px) {
  .collection-section .slick-list {
    max-width: 700px;
  }
  .collection-filter-slider__item {
    margin-left: 32.5px;
    margin-top: 4.5px;
  }
}

@media all and (min-width: 801px) and (max-width: 890.98px) {
  .collection-section .slick-list {
    max-width: 600px;
  }

  .collection-filter-slider__item {
    margin-left: calc((150px - 110px) / 2);
    margin-top: calc((171.1px - 159.85px) / 2);
  }
}

@media all and (min-width: 656px) and (max-width: 800.98px) {
  .collection-section .slick-list {
    max-width: 500px;
  }

  .collection-filter-slider__item {
    margin-left: calc((167px - 110px) / 2);
    margin-top: calc((139.65px - 132.85px) / 2);
  }

  .img-wrapper {
    width: 100px;
    height: 100px;
  }

  .collection-filter-slider__item img {
    width: 90px;
    height: 90px;
  }
}

@media all and (min-width: 575px) and (max-width: 655.98px) {
  .collection-section .slick-list {
    max-width: 400px;
  }
  .collection-filter-slider__item {
    margin-left: calc((134px - 110px) / 2);
    margin-top: calc((171.48px - 159.85px) / 2);
  }
}

@media all and (min-width: 415px) and (max-width: 574.98px) {
  .collection-section .slick-list {
    max-width: 350px;
  }
  .collection-filter-slider__item {
    margin-left: calc((117px - 110px) / 2);
    margin-top: calc((161.79px - 149.85px) / 2);
  }

  .img-wrapper {
    width: 100px;
    height: 100px;
  }

  .collection-filter-slider__item img {
    width: 90px;
    height: 90px;
  }

  .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
}

@media all and (min-width: 376px) and (max-width: 414.98px) {
  .collection-section .slick-list {
    max-width: 290px;
  }
  .collection-filter-slider__item {
    /* margin-left: calc((125px - 97px) / 2); */
    margin-top: calc((146.79px - 134.85px) / 2);
  }

  .img-wrapper {
    width: 85px;
    height: 85px;
  }

  .collection-filter-slider__item img {
    width: 75px;
    height: 75px;
  }

  .icon-wrapper {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }
}

@media all and (max-width: 375.98px) {
  .collection-section .slick-list {
    max-width: 290px;
  }
  .collection-filter-slider__item {
    /* margin-left: calc((115px - 90px) / 2); */
    margin-top: calc((156.82px - 129.85px) / 2);
  }

  .img-wrapper {
    width: 80px;
    height: 80px;
  }

  .collection-filter-slider__item img {
    width: 70px;
    height: 70px;
  }

  .icon-wrapper {
    width: 30px;
    height: 30px;
    top: 40%;
    font-size: 18px;
  }
}
