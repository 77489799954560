.footer {
  background-color: #fff;
  color: #333333;
  border-top: 1px solid #e9e9e9;

  margin: 0;
  padding: 0;
}

.footer-top-container {
  width: 100%;
  max-width: 1000px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
}

.footer-top-content {
  width: 100%;
  max-width: calc(1000px / 3);
  padding-top: 60px;
  height: 260px;

  text-align: center;
}

.footer-top-content .title {
  font-size: 16px;
  font-weight: var(--pretendard-bold);
  line-height: 1.1;
  margin-bottom: 18px;
}

.footer-top-content .menu {
  font-size: 14px;
  line-height: 2;
}

.footer-bottom-container {
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 30px 0;

  text-align: center;
  color: #555555 !important;

  font-size: 11px;
  font-weight: var(--pretendard-medium);
  line-height: 2.18;
}

.footer-bottom-container a {
  color: #555555;
}

.footer-bottom-container .addr {
  margin-bottom: 20px;
}

.footer-bottom-container .copyright {
  display: flex;
  justify-content: center;
  gap: 12px;
}
