.complete-care-layout,
.inspection-layout {
  padding-left: 110px;
  padding-right: 110px;
}

.inspection-section-bg,
.complete-care-section-bg {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 80px 110px 90px 110px;
  /* margin-bottom: 150px; */
}

.inspection-section-bk-bg {
  width: 100%;
  height: 100%;
  background-color: #252525;
  padding: 150px 110px;
}

/* Top Video Section */
.top-video-container {
  /* width: 100vw; */
  height: calc(100vh - 70px);
  margin-bottom: 150px;
  /* border-bottom: 1px solid black; */
  overflow: hidden;
}

.top-video-content {
  /* width: 100%; */
  /* height: calc(100vh - 70px); */
  height: calc((var(--vh, 1vh) * 100) - 70px);
  overflow: hidden;
  position: relative;
}

.top-video-content video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block !important;
}

.player-overlay {
  --overlay-svg-width: 28px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - 70px);
  z-index: 1;
}

.player-overlay-content {
  width: 100%;
  height: 100%;
  color: white;
  text-align: center;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.player-overlay-content .text-box {
  width: 100%;
  margin-bottom: 140px;
}

.player-overlay-content .title {
  font-size: 35px;
  font-weight: var(--pretendard-bold);
  line-height: 1.45;
  margin-bottom: 10px;
}

.player-overlay-content .sub-title {
  font-size: 18px;
  font-weight: var(--pretendard-light);
}

.player-overlay .arrow-down {
  width: var(--overlay-svg-width);
  position: absolute;
  bottom: 30px;
  left: calc(50% - (var(--overlay-svg-width) / 2));
}

.player-overlay .arrow-down svg {
  width: var(--overlay-svg-width);

  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: scale(1);
  }
}

/* Top Image Section */
.top-image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  /* min-height: 25vw; */
  background-size: cover;
  background-position: 50%;
  margin-bottom: 81px;
  background-image: none;
}

.top-image-content {
  width: 100%;
  /* height: 25vw; */
  /* background-color: #1d1b1c; */
  overflow: hidden;
  /* display: flex; */
  align-items: center;
}

.top-image-content img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  /* width: 100% !important; */
  display: block !important;
  border-style: none;
}

/* Intro Section - 진단 및 검증 */
.inspection-section .intro-section-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 150px auto;
}

.inspection-section .intro-section-container .title {
  font-weight: var(--pretendard-bold);
  color: #333333;
  font-size: 50px;
  margin-bottom: 20px;
}

.inspection-section .intro-section-container .desc {
  font-weight: var(--pretendard-medium);
  font-size: 18px;
  color: #333333;
  margin-bottom: 70px;
}

/* Intro Section - 종합관리 */
.intro-section-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 201px auto;
}

.intro-section-container .title {
  font-weight: var(--pretendard-bold);
  color: #333333;
  font-size: 50px;
  margin-bottom: 10px;
}

.intro-section-container .sub-title {
  font-size: 24px;
  color: #999999;
  font-weight: var(--pretendard-bold);
  margin-bottom: 60px;
}

.intro-section-container .desc {
  font-weight: var(--pretendard-medium);
  font-size: 18px;
  color: #333333;
  margin-bottom: 50px;
}

.intro-section-container .video {
  width: 100%;
}

.intro-section-container .video video {
  width: 100%;
  height: 100%;
}

/* Process Section */
.process-section-container {
  width: 100%;
  max-width: 1000px;
  /* margin: 0 auto 150px auto; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.process-section-container .title {
  font-weight: var(--pretendard-bold);
  font-size: 50px;
  color: #333333;
  margin-bottom: 40px;
}

.process-section-container .desc {
  font-weight: var(--pretendard-medium);
  font-size: 18px;
  color: #333333;
  margin-bottom: 60px;
  text-align: center;
}

.process-section-btn a {
  width: 100%;
  max-width: 250px;
}

.process-section-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.process-section-btn button {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  border-radius: 31px;
  border: none;
  /* background-color: var(--point-color); */
  background-color: var(--dark-gray-color);
  color: #fff;
  font-size: 25px;
  font-weight: var(--pretendard-medium);
  text-align: center;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  height: 62px;
}

/* Service Details - 진단 및 검증 */
.inspection-section .service-details-container {
  margin: 0 auto 150px auto;
}

.inspection-section .service-details-container .details {
  gap: 80px;
}

.inspection-section .service-details-container .details .details-row .flex-row {
  gap: 50px !important;
  justify-content: flex-start !important;
}

.inspection-section .service-details-container .detail-card {
  --svg-width: 19.5px;
  max-width: auto !important;
}

.inspection-section .service-details-container .detail-card .detail-card-title {
  margin-bottom: 10px;

  display: flex;
  align-items: center;
}

.inspection-section
  .service-details-container
  .detail-card
  .detail-card-title
  svg {
  width: var(--svg-width);
  height: var(--svg-width);
  margin-right: 8px;
}

.inspection-section .service-details-container .detail-card .detail-card-desc {
  padding-left: calc(var(--svg-width) + 8px);
}

/* Service Details - 종합관리 */
.service-details-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 100px auto;
}

.service-details-container .title {
  font-weight: var(--pretendard-bold);
  font-size: 50px;
  color: #333333;
  margin-bottom: 60px;
}

.service-details-container .details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 74px;
}

.service-details-container .details .details-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 92px;
}

.service-details-container .details .details-row .flex-row {
  justify-content: space-between;
  gap: 90px;
}

.service-details-container .detail-card {
  width: 100%;
  max-width: 443px;
  height: 100%;
  /* max-height: 339px; */
}

.service-details-container .detail-card img {
  width: 120px;
  height: 120px;
  margin: 0 auto 36px auto;
}

.service-details-container .detail-card .detail-card-title {
  font-weight: var(--pretendard-bold);
  font-size: 25px;
  color: #333333;
  margin-bottom: 20px;
}

.service-details-container .detail-card .detail-card-desc {
  font-weight: var(--pretendard-medium);
  font-size: 18px;
  color: #333333;
  line-height: 1.6em;
  word-wrap: break-word;
  word-break: keep-all;
}

/* Equipment Section */
.equipment-section-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.equipment-section-container .title {
  font-weight: var(--pretendard-bold);
  color: #ffffff;
  font-size: 50px;
  margin-bottom: 20px;
}

.equipment-section-container .desc {
  font-weight: var(--pretendard-medium);
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 70px;
}

.equipment-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 50px;
  row-gap: 64px;
}

.equipment-grid .equipment-item {
  --item-font-size: 18px;
  font-size: var(--item-font-size);
  font-weight: var(--pretendard-medium);
  color: #ffffff;

  width: 100%;
  max-width: 300px;
}

.equipment-item .equipment-item-name .name {
  width: max-content;
  line-height: 1;
}

.equipment-item .equipment-item-name {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

.equipment-grid .equipment-item .border-bottom {
  width: auto;
  height: calc(var(--item-font-size) * 0.8);
  min-width: auto;
  margin: 0;

  flex-grow: 1;
  border-bottom: 0.5px solid #fff;
}

.equipment-item-img img {
  width: 100%;
  max-width: 300px;
}

.equipment-slider {
  --arrow-icon-size: 40px;
  --equipment-slider-name-margin: 21px;
  --equipment-slider-name-font-size: 25px;
}

.equipment-slider .slider-icon-wrapper {
  border: none;
  background: transparent;
  color: #aaaaaa;
  border-radius: 0;
  top: calc(50% - (var(--arrow-icon-size) / 2));
}

.equipment-slider .arrow-icon {
  font-size: var(--arrow-icon-size);
}

.equipment-slider .on-right {
  right: calc(
    ((var(--equipment-section-padding) / 2) + (var(--arrow-icon-size) / 2.5)) *
      -1
  );
}

.equipment-slider .on-left {
  left: calc(
    ((var(--equipment-section-padding) / 2) + (var(--arrow-icon-size) / 2.5)) *
      -1
  );
}

.equipment-slider-item-name {
  font-size: var(--equipment-slider-name-font-size);
  font-weight: var(--pretendard-medium);
  margin-top: var(--equipment-slider-name-margin);
  color: #fff;
  text-align: center;
}

.equipment-slider-item-img {
  width: 100%;
}

.equipment-slider-item-img img {
  width: 95%;
  margin: 0 auto;
}

/* responsive */
@media all and (min-width: 1408px) and (max-width: 1919.98px) {
}
@media all and (min-width: 1216px) and (max-width: 1407.98px) {
}
@media all and (min-width: 1024px) and (max-width: 1215.98px) {
  .top-video-container,
  .inspection-section .service-details-container,
  .inspection-section .intro-section-container {
    margin-bottom: 100px;
  }
}

@media all and (max-width: 1160px) {
  .inspection-section-bk-bg {
    padding: 100px 110px;
  }
  .equipment-grid {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    column-gap: 71px;
    row-gap: 15px;
  }

  .equipment-section-container .desc {
    margin-bottom: 62px;
  }

  .equipment-grid .equipment-item,
  .equipment-item-img img {
    max-width: 100% !important;
  }
}
@media all and (max-width: 1024px) {
  .inspection-section
    .service-details-container
    .details
    .details-row
    .flex-row {
    flex-direction: column;
    gap: 43px;
  }

  .inspection-section .service-details-container .details {
    gap: 43px;
  }

  .inspection-section .intro-section-container .desc {
    margin-bottom: 60px;
  }

  .inspection-section .service-details-container,
  .inspection-section .intro-section-container {
    margin-bottom: 100px;
  }

  .inspection-section
    .service-details-container
    .detail-card
    .detail-card-title {
    font-size: 25px;
  }

  .inspection-section
    .service-details-container
    .detail-card
    .detail-card-desc {
    font-size: 18px;
  }

  .inspection-section .service-details-container .detail-card {
    --svg-width: 18px;
  }
}
/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023.98px) {
  .equipment-grid {
    column-gap: 60px;
  }

  .top-video-container {
    margin-bottom: 80px;
  }

  .inspection-section-bk-bg {
    padding: 80px;
  }

  .intro-section-container {
    margin: 0 auto 170px auto;
  }

  .complete-care-layout,
  .inspection-layout {
    padding-left: 80px;
    padding-right: 80px;
  }

  .inspection-section-bg,
  .complete-care-section-bg {
    padding: 80px 80px 90px 80px;
  }

  .service-details-container .details .details-row .flex-row {
    gap: 60px;
  }

  .service-details-container .detail-card .detail-card-title {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .service-details-container .detail-card .detail-card-desc {
    font-size: 17px;
  }
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 621px) and (max-width: 767.98px) {
  .top-video-container {
    margin-bottom: 80px;
  }

  .equipment-grid {
    column-gap: 40px;
  }

  .equipment-grid .equipment-item {
    --item-font-size: 17px;
  }

  .inspection-section-bk-bg {
    --equipment-section-padding: 80px;
    padding: var(--equipment-section-padding);
  }

  .intro-section-container {
    margin: 0 auto 160px auto;
  }

  .service-details-container {
    margin: 0 auto 80px auto;
  }

  .complete-care-layout,
  .inspection-layout {
    padding-left: 80px;
    padding-right: 80px;
  }

  .inspection-section-bg,
  .complete-care-section-bg {
    padding: 80px 80px 90px 80px;
  }
}

@media all and (max-width: 768.98px) {
  .service-details-container .title,
  .process-section-container .title {
    margin-bottom: 15px;
  }
  .service-details-container .details .details-row .flex-row {
    flex-direction: column;
    gap: 50px;
  }

  .service-details-container .details {
    gap: 50px;
  }

  .service-details-container .detail-card {
    max-width: none;
  }
}
@media all and (max-width: 620.98px) {
  /* grid 슬라이드로 변경 */
}
@media all and (min-width: 561px) and (max-width: 620.98px) {
  .top-video-container {
    margin-bottom: 80px;
  }

  .inspection-section-bk-bg {
    --equipment-section-padding: 80px;
    padding: var(--equipment-section-padding);
  }

  .intro-section-container {
    margin: 0 auto 150px auto;
  }

  .intro-section-container .desc {
    margin-bottom: 30px;
  }

  .service-details-container {
    margin: 0 auto 80px auto;
  }

  .top-image-container {
    margin-bottom: 50px;
  }

  .complete-care-layout,
  .inspection-layout {
    padding-left: 70px;
    padding-right: 70px;
  }

  .inspection-section-bg,
  .complete-care-section-bg {
    padding: 70px 70px 80px 70px;
  }

  .complete-care-section .title,
  .inspection-section .title,
  .inspection-section-bg .title,
  .complete-care-section-bg .title {
    font-size: 45px;
  }
}

/* 모바일 기준 */
@media all and (max-width: 560.98px) {
  .inspection-section
    .service-details-container
    .details
    .details-row
    .flex-row {
    gap: 37px;
  }

  .inspection-section .intro-section-container .title,
  .equipment-section-container .title {
    font-size: 8.929vw;
  }
  .inspection-section .intro-section-container .desc {
    font-size: 17px;
    margin-bottom: 50px;
  }

  .top-video-container,
  .inspection-section .service-details-container,
  .inspection-section .intro-section-container {
    margin-bottom: 60px;
  }

  .inspection-layout {
    padding-left: 60px;
    padding-right: 60px;
  }

  .player-overlay-content {
    justify-content: center;
  }

  .player-overlay-content .text-box {
    margin-bottom: 0;
  }

  .player-overlay-content .title {
    margin-bottom: 17px;
  }

  .player-overlay .arrow-down {
    bottom: calc(100% * 0.11) !important;
  }

  .equipment-slider {
    --arrow-icon-size: 30px;
  }

  .intro-section-container {
    margin: 0 auto 75px auto;
  }

  .intro-section-container .desc {
    margin-bottom: 45px;
  }

  .intro-section-container .sub-title {
    margin-bottom: 50px;
  }

  .process-section-container {
    margin: 0 auto;
  }

  .service-details-container {
    margin: 0 auto 70px auto;
  }

  .top-image-container {
    margin-bottom: 40px;
  }

  .process-section-container .desc {
    font-size: 17px;
    word-wrap: break-word;
    word-break: keep-all;
    margin-bottom: 30px;
  }

  .service-details-container .details .details-row .flex-row,
  .service-details-container .details {
    gap: 50px;
  }

  .service-details-container .detail-card {
    width: 84%;
    margin: 0 auto;
  }

  .inspection-section .service-details-container .detail-card {
    width: 100%;
    margin: 0;
  }

  .service-details-container .detail-card .detail-card-title {
    font-size: 22px;
    margin-bottom: 2px;
  }

  .service-details-container .detail-card .detail-card-desc {
    font-size: 17px;
  }

  .service-details-container .detail-card img {
    width: 85px;
    height: 85px;
  }
}

@media all and (max-width: 520px) {
  .equipment-slider {
    --arrow-icon-size: 30px;
    --equipment-slider-name-margin: 21px;
    --equipment-slider-name-font-size: 22px;
  }
}

@media all and (min-width: 415px) and (max-width: 560.98px) {
  .inspection-section-bk-bg {
    --equipment-section-padding: 60px;
    padding: var(--equipment-section-padding);
  }

  .complete-care-layout {
    padding-left: 40px;
    padding-right: 40px;
  }

  .inspection-section-bg,
  .complete-care-section-bg {
    padding: 40px 40px 60px 40px;
  }

  .complete-care-section .title,
  .inspection-section .title,
  .inspection-section-bg .title,
  .complete-care-section-bg .title {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

@media all and (max-width: 414.98px) {
  .equipment-slider {
    --arrow-icon-size: 30px;
    --equipment-slider-name-margin: 21px;
    --equipment-slider-name-font-size: 20px;
  }

  .top-video-container,
  .inspection-section .service-details-container,
  .inspection-section .intro-section-container {
    margin-bottom: 44px;
  }

  .inspection-section-bk-bg {
    --equipment-section-padding: 60px;
    padding: 44px var(--equipment-section-padding);
  }

  .inspection-section .intro-section-container .title,
  .equipment-section-container .title {
    margin-bottom: 17px;
  }

  .inspection-section .intro-section-container .desc {
    font-size: 16px;
    margin-bottom: 45px;
  }

  .complete-care-layout {
    padding-left: 30px;
    padding-right: 30px;
  }

  .inspection-section-bg,
  .complete-care-section-bg {
    padding: 40px 30px 60px 30px;
  }

  .complete-care-section .title,
  .inspection-section .title,
  .inspection-section-bg .title,
  .complete-care-section-bg .title {
    font-size: 30px;
  }

  .player-overlay-content .title {
    font-size: 30px;
  }

  .player-overlay-content .sub-title {
    font-size: 16px;
  }
}

@media all and (max-width: 375px) {
  .equipment-slider {
    --arrow-icon-size: 30px;
    --equipment-slider-name-margin: 21px;
    --equipment-slider-name-font-size: 19px;
  }

  .inspection-section
    .service-details-container
    .details
    .details-row
    .flex-row {
    gap: 30px;
  }

  .inspection-section .intro-section-container .title,
  .equipment-section-container .title {
    font-size: 29px;
    margin-bottom: 14px;
  }

  .inspection-section .intro-section-container .desc {
    margin-bottom: 35px;
  }

  .inspection-section
    .service-details-container
    .detail-card
    .detail-card-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .inspection-section
    .service-details-container
    .detail-card
    .detail-card-desc {
    font-size: 16px;
  }
}
