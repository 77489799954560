.privacy-section {
  padding: 100px;
}

.privacy-container .ant-table {
  width: 100%;
  /* overflow-x: scroll; */
  word-wrap: break-word;
  word-break: keep-all;
}

.privacy-container h2 {
  font-weight: var(--pretendard-bold);
  margin-top: 50px;
}

.privacy-container p,
.privacy-container h2,
.privacy-container h3 {
  text-align: left;
  word-wrap: break-word;
  word-break: keep-all;
}

.privacy-container h3 {
  font-weight: var(--pretendard-bold);
  margin-top: 30px;
}

.indent {
  text-indent: 30px;
}

.circle-number {
  font-size: 11.2px;
}

.privacy-container thead th {
  font-weight: var(--pretendard-bold);
}

.table-column-header tr td:nth-child(1) {
  background-color: #fafafa;
  font-weight: var(--pretendard-bold);
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767px) {
}
@media all and (min-width: 560px) and (max-width: 650px) {
  .privacy-section {
    padding: 80px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 360px) and (max-width: 560px) {
  .privacy-section {
    padding: 60px;
  }

  .privacy-container .ant-table-cell {
    font-size: 9.6px;
  }
  .indent {
    text-indent: 10px;
  }
}
@media all and (max-width: 359px) {
  .privacy-section {
    padding: 40px;
  }
  .indent {
    text-indent: 10px;
  }
}
