.collection-slider-container {
  /* margin-bottom: 50px; */
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.slider-icon-wrapper {
  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: 50px;
  max-height: 50px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.2px solid #707070;
  background: white;
  font-size: 30px;
  color: #666;
}

.on-right {
  right: 0;
}

.on-left {
  left: 0;
}

.collection-slider-container .slick-list {
  margin: 0 30px;
}

.collection-slider-card {
  max-width: 180px;
  /* width: 200px; */
  margin: 0 auto;
  overflow: hidden;
}

.collection-slider-card img {
  width: 100%;
  /* width: auto; */
  /* height: 280px; */
  margin: 0 auto;
}

/* .ant-card {
  padding: 10px;
} */

.collection-slider-card .ant-card-meta {
  text-align: center;
  justify-content: center;
}

.collection-slider-card .ant-card-body {
  padding: 10px 5px;
}

.collection-slider-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  /* padding: 20px; */
}

.collection-slider-card h2 {
  font-weight: var(--pretendard-extrabold);
  font-size: 19.2px;
  margin-bottom: 10px;
}

.mr-meta {
  margin-right: 7%;
}

.collection-slider-card p {
  font-weight: var(--pretendard-semibold);
  font-size: 16px;
  margin-bottom: 0;
}

@media all and (min-width: 2000px) {
  /* .home-section {
    padding-left: 300px;
    padding-right: 300px;
  } */
}
/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
  /* .collection-slider-container .slick-list {
    margin: 0 40px;
  } */

  .collection-slider-card img {
    width: 80%;
  }

  .slider-icon-wrapper {
    max-width: 40px;
    max-height: 40px;
    font-size: 25px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767px) {
  .slider-icon-wrapper {
    max-width: 30px;
    max-height: 30px;
    font-size: 20px;
  }

  .collection-slider-card img {
    width: 80%;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-title {
    font-size: 16px;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-description {
    font-size: 13px;
  }
}
@media all and (min-width: 560px) and (max-width: 649px) {
  .slider-icon-wrapper {
    max-width: 30px;
    max-height: 30px;
    font-size: 20px;
  }

  .collection-slider-card img {
    width: 80%;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-title {
    font-size: 16px;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-description {
    font-size: 13px;
  }
}
@media all and (min-width: 380px) and (max-width: 559px) {
  .slider-icon-wrapper {
    max-width: 30px;
    max-height: 30px;
    font-size: 20px;
  }

  .collection-slider-card img {
    width: 80%;
  }

  .collection-slider-card h2 {
    font-size: 16px;
  }
  .collection-slider-card p {
    font-size: 12.8px;
  }
  .mr-meta {
    margin-right: 7%;
  }
  .collection-slider-card .ant-card-meta .ant-card-meta-title {
    font-size: 15px;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-description {
    font-size: 12px;
  }
}

@media all and (min-width: 340px) and (max-width: 379px) {
  .slider-icon-wrapper {
    max-width: 30px;
    max-height: 30px;
    font-size: 20px;
  }

  .collection-slider-card img {
    width: 80%;
  }

  .collection-slider-card h2 {
    font-size: 16px;
  }
  .collection-slider-card p {
    font-size: 12.8px;
  }
  .mr-meta {
    margin-right: 5%;
  }
  .collection-slider-card .ant-card-meta .ant-card-meta-title {
    font-size: 13px;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-description {
    font-size: 11px;
  }
}
@media all and (max-width: 339px) {
  .collection-slider-card img {
    height: 140px;
  }
  .collection-slider-card h2 {
    font-size: 16px;
  }
  .collection-slider-card p {
    font-size: 12.8px;
  }
  .mr-meta {
    margin-right: 0;
  }
  .collection-slider-card .ant-card-meta .ant-card-meta-title {
    font-size: 13px;
  }

  .collection-slider-card .ant-card-meta .ant-card-meta-description {
    font-size: 11px;
  }
}
