.support-container {
  width: 100%;
  height: 100%;

  display: flex;

  margin-top: 100px;
  margin-bottom: 150px;
  padding-left: 50px;
}

.support-left {
  width: 100%;
  height: 573px;
  max-width: 325px;

  margin-right: calc(100vw * 0.056);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.support-left .title {
  font-size: 60px;
  font-weight: var(--pretendard-black);
}

.support-left .email .email-title {
  font-size: 18px;
  font-weight: var(--pretendard-black);
}

.support-left .email .email-content {
  font-size: 28px;
  font-weight: var(--pretendard-light);
}

.support-left .email .email-content span {
  font-weight: var(--pretendard-black);
}

.support-left .boutique {
  cursor: pointer;
}

.support-left .boutique .boutique-title {
  font-size: 18px;
  font-weight: var(--pretendard-black);
  margin-bottom: 20px;
}

.support-left .boutique .boutique-addr {
  font-size: 20px;
  margin-bottom: 20px;
}

.support-left .boutique .boutique-phone {
  font-size: 20px;
  font-weight: var(--pretendard-extrabold);
}

.support-container .ant-divider {
  height: 768px;
  margin: 0;
  border-left: 1px solid #c6c6c6;
}

.support-right {
  width: 100%;
  height: 100%;
  max-width: 1700px;
  max-height: 768px;
  padding: 0 calc(100vw * 0.11);
}

.support-right .show-on-mobile {
  display: none;
}

.support-right .top-message {
  font-size: 20px;
  margin-bottom: 50px;
  text-align: center;
  word-wrap: break-word;
  word-break: keep-all;
}

@media all and (max-width: 1103.98px) {
  .support-container .ant-divider {
    height: 800px;
  }

  .support-right {
    max-height: 800px;
  }
}

@media all and (min-width: 1024px) and (max-width: 1215.98px) {
  .support-left .title {
    font-size: 55px;
  }

  .support-left .email .email-content {
    font-size: 25px;
  }
} /* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023.98px) {
  .support-left {
    margin-right: calc(100vw * 0.02);
  }

  .support-left .title {
    font-size: 48px;
  }

  .support-left .email .email-title {
    font-size: 17px;
  }

  .support-left .email .email-content {
    font-size: 20px;
  }
  .support-left .boutique .boutique-title {
    font-size: 17px;
    margin-bottom: 18px;
  }

  .support-left .boutique .boutique-addr {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .support-left .boutique .boutique-phone {
    font-size: 18px;
  }

  .support-right {
    padding: 0 calc(100vw * 0.1);
  }
}
@media all and (max-width: 767.98px) {
  .support-left,
  .support-container .ant-divider {
    display: none;
  }

  .support-container {
    margin-top: 50px;
    padding: 0;
  }

  .support-right {
    max-height: 100%;
  }

  .support-right .show-on-mobile {
    display: block;
  }

  .support-right .mobile-title {
    font-size: 60px;
    font-weight: var(--pretendard-black);
    text-align: center;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .support-container {
    margin-bottom: 120px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 414px) and (max-width: 559.98px) {
  .support-container {
    margin-top: 40px;
    margin-bottom: 100px;
  }

  .support-right .mobile-title {
    font-size: 50px;
  }

  .support-right .top-message {
    font-size: 17px;
  }
}
@media all and (max-width: 413.98px) {
  .support-container {
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .support-right .mobile-title {
    font-size: 45px;
  }

  .support-right .top-message {
    font-size: 16px;
    margin-bottom: 40px;
  }
}
