.cpo-cert-container {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cpo-cert-container-column {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
}

.cpo-cert-container .content-container,
.cpo-cert-container-column .content-container {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  justify-content: space-between;
}

.cpo-cert-container .title,
.cpo-cert-container-column .title {
  font-size: 50px;
  font-weight: var(--pretendard-bold);
}

.cpo-cert-container .column-2,
.cpo-cert-container-column .column-2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 462px;
}

.cpo-cert-container .column-2 .paragraph,
.cpo-cert-container-column .column-2 .paragraph {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.column-2 .paragraph .no {
  font-size: 120px;
  font-weight: var(--pretendard-bold);
  line-height: 0.9;
}

.paragraph-3 .content {
  justify-content: flex-start !important;
}

.column-2 .paragraph .content {
  margin-left: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.column-2 .paragraph .content .content-title {
  font-size: 24px;
  font-weight: var(--pretendard-semibold);
}

.column-2 .paragraph .content-desc {
  font-size: 17px;
}

.cpo-cert-container .column-3,
.cpo-cert-container-column .column-3 {
  width: 100%;
  max-width: 380px;
  height: 100%;
  max-height: 463px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.cpo-cert-container .column-3 .cpo-mark-wrapper,
.cpo-cert-container-column .column-3 .cpo-mark-wrapper {
  width: 100%;
  max-width: 380px;
  /* height: 100%; */
  /* max-height: 380px; */
  aspect-ratio: 1/1;
  display: flex;
  border: 0.3px solid #707070;
}

.cpo-cert-container .column-3 .cpo-mark-wrapper img,
.cpo-cert-container-column .column-3 .cpo-mark-wrapper img {
  position: relative;
  /* left: calc(50% - 250px / 2); */
  /* top: calc(50% - 250px / 2); */
  /* for safari */
  -webkit-align-self: center;
  align-self: center;
  width: 250px;
  height: 250px;
  margin: auto;
}

.cpo-cert-container .column-3 .switch,
.cpo-cert-container-column .column-3 .switch {
  width: 100%;
  height: fit-content;
}

/* responsive */

@media all and (min-width: 1470px) and (max-width: 1919.98px) {
}
@media all and (min-width: 1260px) and (max-width: 1469.98px) {
  .cpo-cert-container .title,
  .cpo-title {
    font-size: 40px;
  }

  .cpo-cert-container .column-2 {
    width: 100%;
    max-width: 380px;
  }

  .column-2 .paragraph .no {
    font-size: 95px;
    line-height: 0.9;
  }

  .column-2 .paragraph .content {
    margin-left: 30px;
  }

  .column-2 .paragraph .content .content-title {
    font-size: 24px;
  }

  .cpo-cert-container .column-3 .cpo-mark-wrapper {
    width: 85%;
  }

  .cpo-cert-container .column-3 .cpo-mark-wrapper img {
    /* left: calc(50% - 200px / 2); */
    /* top: calc(50% - 200px / 2); */
    width: 200px;
    height: 200px;
  }
}
@media all and (min-width: 768px) and (max-width: 1259.98px) {
  .cpo-cert-container .column-3 {
    margin-left: 30px;
  }
}
@media all and (min-width: 950px) and (max-width: 1100.98px) {
  .column-2 .paragraph .no {
    font-size: 100px;
  }

  /* .cpo-cert-container .column-3 .cpo-mark-wrapper {
    width: 85%;
  }

  .cpo-cert-container .column-3 .cpo-mark-wrapper img {
    left: calc(50% - 200px / 2);
    top: calc(50% - 200px / 2);
    width: 200px;
    height: 200px;
  } */
}
@media all and (min-width: 768px) and (max-width: 949.98px) {
  .cpo-cert-container .title,
  .cpo-title {
    font-size: 45px;
  }

  .cpo-cert-container .column-2 {
    max-width: 400px;
  }

  .column-2 .paragraph .no {
    font-size: 80px;
  }

  .column-2 .paragraph .content {
    margin-left: 30px;
  }

  .column-2 .paragraph .content .content-title {
    font-size: 20px;
  }

  /*
  .cpo-cert-container .column-3 .cpo-mark-wrapper {
    width: 80%;
    aspect-ratio: 1/1;
  } */

  /* .cpo-cert-container .column-3 .cpo-mark-wrapper img {
    left: calc(50% - 180px / 2);
    top: calc(50% - 180px / 2);
    width: 180px;
    height: 180px;
  } */
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 460px) and (max-width: 559.98px) {
  .cpo-cert-container-column .title,
  .cpo-title {
    font-size: 45px;
  }

  .column-2 .paragraph .no {
    font-size: 90px;
  }
}
@media all and (min-width: 400px) and (max-width: 459.98px) {
  .cpo-cert-container-column .title,
  .cpo-title {
    font-size: 40px;
  }

  .column-2 .paragraph .content {
    margin-left: 30px;
  }

  .column-2 .paragraph .no {
    font-size: 80px;
  }

  .column-2 .paragraph .content .content-title {
    font-size: 20px;
  }

  .column-2 .content-desc {
    font-size: 13px;
  }
}
@media all and (min-width: 360px) and (max-width: 399.98px) {
  .cpo-cert-container-column .title,
  .cpo-title {
    font-size: 35px;
  }

  .column-2 .paragraph .content {
    margin-left: 30px;
  }

  .column-2 .paragraph .no {
    font-size: 70px;
  }

  .column-2 .paragraph .content .content-title {
    font-size: 20px;
  }

  .column-2 .content-desc {
    font-size: 13px;
  }

  .cpo-cert-container-column .column-3 .cpo-mark-wrapper img {
    /* left: calc(50% - 200px / 2);
    top: calc(50% - 200px / 2); */
    width: 200px;
    height: 200px;
  }
}
@media all and (max-width: 359.98px) {
  .cpo-cert-container-column .title,
  .cpo-title {
    font-size: 45px;
  }
}
