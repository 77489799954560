.contact-banner-container {
  width: 100%;
  height: 195px;
  /* background-color: #f7f7f7; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contact-banner-container .left,
.contact-banner-container .right {
  width: fit-content;
  max-width: 720px;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* padding: 0 60px; */
}

.contact-banner-container .left {
  margin-right: 67px;
}

.contact-banner-container .right {
  margin-left: 67px;
}

.contact-banner-container .text-box {
  width: fit-content;
  max-width: 472px;
  height: 100%;
  max-height: 89px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
}

.contact-banner-container .text-box .content {
  font-size: 20px;
  font-weight: var(--pretendard-medium);
  color: #333333;
  margin-bottom: 26.7px;
}

.contact-banner-container .text-box .link {
  font-size: 12px;
  font-weight: var(--pretendard-medium);
  color: #333333;
}

.contact-banner-container img {
  width: 100px;
  height: 100px;
}

.contact-banner-container .ant-divider-vertical {
  border-left: 1px solid #d5d5d5;
  height: 120px;
  margin: auto 0;
}

@media all and (min-width: 1216px) and (max-width: 1351.98px) {
  .contact-banner-container .left {
    margin-right: 65px;
  }

  .contact-banner-container .right {
    margin-left: 65px;
  }

  .contact-banner-container .text-box {
    max-height: 85px;
  }

  .contact-banner-container .text-box .content {
    font-size: 17px;
  }

  .contact-banner-container .text-box .link {
    font-size: 12px;
  }

  .contact-banner-container img {
    width: 90px;
    height: 90px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1215.98px) {
  .contact-banner-container {
    height: 170px;
  }

  .contact-banner-container .left {
    margin-right: 40px;
  }

  .contact-banner-container .right {
    margin-left: 40px;
  }

  .contact-banner-container .text-box {
    max-height: 80px;
  }

  .contact-banner-container .text-box .content {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .contact-banner-container .text-box .link {
    font-size: 12px;
  }

  .contact-banner-container img {
    width: 75px;
    height: 75px;
  }
} /* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023.98px) {
  /* .contact-banner-container {
    height: 170px;
  } */
  .contact-banner-container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .contact-banner-container .left {
    margin-right: 30px;
  }

  .contact-banner-container .right {
    margin-left: 30px;
  }

  /* .contact-banner-container .text-box {
    max-height: 80px;
  } */

  .contact-banner-container .text-box .content {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .contact-banner-container .text-box .link {
    font-size: 12px;
  }

  .contact-banner-container img {
    width: 75px;
    height: 75px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
  /* .contact-banner-container {
    height: 170px;
  } */
  .contact-banner-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .contact-banner-container .left {
    margin-right: 30px;
  }

  .contact-banner-container .right {
    margin-left: 30px;
  }

  /* .contact-banner-container .text-box {
    max-height: 80px;
  } */

  .contact-banner-container .text-box .content {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .contact-banner-container .text-box .link {
    font-size: 12px;
  }

  .contact-banner-container img {
    width: 70px;
    height: 70px;
  }
}
@media all and (max-width: 649.98px) {
  .contact-banner-container {
    display: none;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 360px) and (max-width: 559.98px) {
}
@media all and (max-width: 359.98px) {
}
