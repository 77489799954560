/* 모바일 세로 화면 2 */
@media (max-width: 359.99px) {
  .section-container {
    padding: 0 50px;
  }

  .home-section {
    padding: 40px 20px 20px 20px;
  }
  .store-section {
    padding-top: 70px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 10%);
  }
  .section-title-img {
    height: 25px !important;
  }
  .section-title {
    font-size: 27.2px;
    margin-bottom: 40px;
  }
  .title-above {
    font-size: 20.8px;
  }
  .section-w-bg {
    padding: 90px 50px !important;
  }
  .btn-animated button {
    font-size: 20px !important;
    width: 300px !important;
  }

  .oval-btn-lg button {
    font-size: 18px !important;
    width: 100% !important;
  }

  .section-title-lg {
    font-size: 28.8px;
  }

  .section-sub-title {
    font-size: 16px;
  }
}

/* 모바일 세로 화면 1 */
@media (min-width: 360px) and (max-width: 575.98px) {
  .section-container {
    padding: 0 50px;
  }

  .section-start-gap {
    margin-bottom: 80px;
  }
  .home-section {
    padding: 40px 20px 20px 20px;
  }
  .store-section {
    padding-top: 90px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 10%);
  }
  .section-title-img {
    height: 25px !important;
  }
  .section-title {
    font-size: 32px;
    margin-bottom: 50px;
  }

  .title-above {
    font-size: 20.8px;
  }

  .section-w-bg {
    padding: 90px 50px !important;
  }
  .btn-animated button {
    font-size: 23px !important;
    width: 330px !important;
  }

  .oval-btn-lg button {
    font-size: 20px !important;
    width: 100% !important;
  }

  .section-title-lg {
    font-size: 28.8px;
  }

  .section-sub-title {
    font-size: 16px;
  }
}

/* 모바일 가로 화면 2 */
@media (min-width: 576px) and (max-width: 649.99px) {
  .section-container {
    padding: 0 80px;
  }
  .home-section {
    padding: 40px 60px 20px 60px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 500px);
  }
  .section-title-img {
    height: 35px;
  }
  .section-w-bg {
    padding: 90px 80px !important;
  }

  .section-title {
    font-size: 32px;
  }

  .section-title-lg {
    font-size: 40px;
  }

  .section-sub-title {
    font-size: 14.4px;
  }
}

/* 모바일 가로 화면 1 */
@media (min-width: 650px) and (max-width: 767.98px) {
  .section-container {
    padding: 0 80px;
  }
  .home-section {
    padding: 40px 60px 20px 60px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 500px);
  }
  .section-title-img {
    height: 35px;
  }
  .section-w-bg {
    padding: 90px 80px !important;
  }

  .section-title {
    font-size: 33.6px;
  }

  .section-title-lg {
    font-size: 40px;
  }

  .section-sub-title {
    font-size: 16px;
  }
}

/* 태블릿(갤럭시 탭, 아이패드) 화면 */
@media (min-width: 768px) and (max-width: 991.98px) {
  .section-container {
    padding: 0 80px;
  }
  /* .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 700px);
  } */
  .home-section {
    padding: 40px 60px 20px 60px;
  }

  .section-title-img {
    height: 35px;
  }
  .section-w-bg {
    padding: 90px 80px !important;
  }

  .section-title {
    font-size: 36.8px;
  }

  .section-title-lg {
    font-size: 44.8px;
  }

  .section-sub-title {
    font-size: 17.6px;
  }
}

/* PC 화면 1200px 이하 */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .section-container {
    padding: 0 80px;
  }
  .section-w-bg {
    padding: 90px 80px !important;
  }
}

/* PC 화면 1200px 이상 1920px 이하 */
@media (min-width: 1200px) and (max-width: 1599.99px) {
  .section-container {
    padding: 0 80px;
  }
  .card-title {
    width: 100%;
    font-size: 21px;
    font-weight: var(--pretendard-medium);
    color: #333333;
  }

  .card-desc {
    width: 100%;
    font-size: 15px;
    color: #333333;
  }

  .section-w-bg {
    padding: 90px 80px !important;
  }
}

/* PC 화면 1200px 이상 1920px 이하 */
@media (min-width: 1600px) and (max-width: 1919.99px) {
  .section-container {
    padding: 0 100px;
  }
  .card-title {
    width: 100%;
    font-size: 23px;
    font-weight: var(--pretendard-medium);
    color: #333333;
  }

  .card-desc {
    width: 100%;
    font-size: 17px;
    color: #333333;
  }

  .section-w-bg {
    padding: 90px 160px !important;
  }
}
