/* Common start */
.process-container {
  max-width: 1530px;
  margin: 0 auto !important;
}

.process-box {
  display: flex;
  flex-direction: column;
  height: 360px;
  max-height: 360px;
  border-radius: 27px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.process-icon {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #f8f8f8;
  border-top-left-radius: 27px;
  border-top-right-radius: 27px;
  max-height: 142px;
}
.process-icon img {
  max-width: 100px;
  max-height: 100px;
  margin: 26px 0 16px 0;
}

.process-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 30px 40px 45px 44px;
  background-color: #fff;
  border-bottom-left-radius: 27px;
  border-bottom-right-radius: 27px;
  max-height: 218px;
}

.process-content .process-no {
  font-weight: var(--pretendard-medium);
  font-size: 16px;
  color: #666666;
}

.process-content .process-title {
  font-weight: var(--pretendard-medium);
  font-size: 24px;
  color: #333333;
  margin-top: 6px;
}

.process-content .process-desc {
  font-size: 17px;
  color: #333333;
  margin-top: 20px;
}

.content-with-link {
  padding-bottom: 10px !important;
  justify-content: space-between;
}

.process-content__link {
  text-align: right;
  /* height: 100%; */
  /* padding: 0px 40px 0px 0px; */
  padding-top: 10px;
}
/* Common end */

/* BespokeProcess start */
.bespoke-process-container .section-title {
  margin-bottom: 0 !important;
}
/* BespokeProcess end */

/* BuyProcess start */
/* BuyProcess end */

/* TradeProcess start */
.trade-process-container .section-title {
  margin-bottom: 0 !important;
}
/* TradeProcess end */

/* BespokeProcess start */
/* BespokeProcess end */

/* 반응형 start */
/* 모바일 세로 화면 */
@media (max-width: 575.98px) {
  .process-content .process-no {
    font-size: 14.4px;
  }

  .process-content .process-title {
    font-size: 19.2px;
    margin-top: 4px;
  }

  .process-content .process-desc {
    font-size: 15px;
    margin-top: 15px;
  }
  .process-content {
    padding: 30px 35px 35px 35px;
  }
}

/* 모바일 가로 화면 */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* 태블릿(갤럭시 탭, 아이패드) 화면 */
@media (min-width: 768px) and (max-width: 991.98px) {
  .process-content .process-no {
    font-size: 14.4px;
  }

  .process-content .process-title {
    font-size: 20.8px;
    margin-top: 4px;
  }

  .process-content .process-desc {
    font-size: 15px;
    margin-top: 15px;
  }
  .process-content {
    padding: 30px 35px 35px 35px;
  }
}

/* PC 화면 1200px 이하 */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1200px) and (max-width: 1450.99px) {
  .process-icon img {
    max-width: 70px;
    max-height: 70px;
    margin: 26px 0 16px 0;
  }
  .process-content .process-no {
    font-size: 12.8px;
  }

  .process-content .process-title {
    font-size: 17.6px;
    margin-top: 4px;
  }

  .process-content .process-desc {
    font-size: 15px;
    margin-top: 15px;
  }
  .process-content {
    padding: 40px 35px 35px 35px;
  }
}

/* PC 화면 1200px 이상 1920px 이하 */
@media (min-width: 1451px) and (max-width: 1919.98px) {
  .process-icon img {
    max-width: 80px;
    max-height: 80px;
    margin: 26px 0 16px 0;
  }
  .process-content .process-no {
    font-size: 14.4px;
  }

  .process-content .process-title {
    font-size: 20.8px;
    margin-top: 4px;
  }

  .process-content .process-desc {
    font-size: 15px;
    margin-top: 15px;
  }
  .process-content {
    padding: 30px 35px 35px 35px;
  }
}

@media (min-width: 1920px) {
  .process-content .process-desc {
    margin-top: 10px !important;
  }

  .process-content .process-title {
    margin-top: 0px !important;
  }
}
/* 반응형 end */
