.footer-mobile {
  background-color: #ffffff;
  margin: 0;
  padding: 0;

  border-top: 1px solid #e9e9e9;
}

.footer-collapse-container
  .ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header {
  padding: 20px 40px 18px 22px;
}

.footer-collapse-container
  .ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 22px;
}

.footer-collapse-container .ant-collapse-content > .ant-collapse-content-box {
  padding: 6px 40px 12px 22px !important;
}

.footer-collapse-container .ant-collapse-header-text {
  font-size: 11px;
  font-weight: var(--pretendard-bold);
}

.footer-collapse-container .collapse-content {
  font-size: 11px;
  line-height: 1.9;
}

.footer-collapse-container .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #e9e9e9;
}

.footer-collapse-container .company-info {
  font-size: 10px;
  font-weight: var(--pretendard-medium);

  padding: 20px 22px;
  color: #555555;
}

.footer-collapse-container .company-info .addr a {
  color: #555555;
}

.footer-collapse-container .company-info .addr {
  margin-bottom: 21px;
}

.footer-collapse-container .company-info .copyright {
  display: flex;
  align-items: center;
  gap: 16px;
}
