/* Segmented Radio Group start */
.segmented-radio-group {
  width: 100%;
}
.segmented-radio-group .ant-radio-button-wrapper {
  width: 50%;
  height: 50px;
  text-align: center;
}

.segmented-radio-group .ant-radio-button-wrapper {
  border-color: var(--point-color);
}

.segmented-radio-group
  .ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-right-color: var(--point-color);
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  background: var(--point-color);
  border-color: var(--point-color);
}

.segmented-radio-group .ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px;
  border-left: 1px solid var(--point-color);
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--point-color);
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--point-color);
}

.ant-radio-button-wrapper:hover {
  color: var(--point-color);
}

.ant-radio-button-wrapper:focus-within,
.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):focus-within {
  box-shadow: 0 0 0 3px var(--point-shadow-color);
}

.ant-radio-group-large .ant-radio-button-wrapper {
  font-size: 19.2px;
  line-height: 48px;
  font-weight: var(--pretendard-medium);
}

/* Segmented Toggle start */
.segmented-row {
  margin-top: 100px;
}

.segmented-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-segmented {
  background-color: var(--point-color);
}

.ant-segmented:not(.ant-segmented-disabled):hover,
.ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: var(--point-color);
}

.ant-segmented-item-selected {
  color: #1d1b1c !important;
}

.ant-segmented-item,
.ant-segmented
  .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(
    .ant-segmented-item-disabled
  ) {
  color: #fff;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  min-height: 40px;
  line-height: 40px;
  padding: 0 20px;
  font-size: 20.8px;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item,
.ant-segmented.ant-segmented-lg .ant-segmented-thumb {
  border-radius: 48px;
}

.ant-segmented.ant-segmented-lg {
  border-radius: 50px;
}
/* Segmented Toggle end */
