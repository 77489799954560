.cpo-engineer-container .title {
  font-size: 50px;
  font-weight: var(--pretendard-bold);
  margin-bottom: 70px;
  height: 100%;
  display: flex;
}

.cpo-engineer-container .inner-container {
  width: 100%;
  /* max-width: 1680px; */
  /* margin-left: auto; */
}

.box-container {
  width: 100%;
  height: 600px;
  /* position: relative; */
}

.box-right-container {
  width: 100%;
  height: 600px;
  position: relative;
}

.box-container .box-left {
  position: absolute;
  z-index: 2;
  background-color: transparent;
  width: calc(100vw * 0.35);
  max-width: 1500px;
  /* max-width: 628px; */
  height: 100%;
  max-height: 450px;
  overflow: hidden;
}

.box-container .box-left img {
  height: 100%;
  /* max-width: 500px; */
  object-fit: cover;
  max-height: 450px;
}

.box-container .box-right {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 86px;
  /* width: 100%; */
  width: calc(100vw * 0.65);
  /* max-width: 1182px; */
  /* height: 100%; */
  height: 450px;
  overflow: hidden;
  background-color: #f2f2f4;
  padding-top: 91px;
  padding-right: 130px;
}

.box-container .box-right .content {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.box-container .box-right .paragraph {
  width: 100%;
  max-width: 369px;
  height: 100%;
  /* font-size: 10px; */
}

.box-container .box-right .paragraph:last-child {
  margin-left: 90px;
}

.box-container .box-right .paragraph-title {
  font-size: 35px;
  /* font-size: 3.5em; */
  font-weight: var(--pretendard-semibold);
  margin-bottom: 27px;
}

.box-container .box-right .paragraph-desc {
  font-size: 17px;
  /* font-size: 1.4em; */
  word-break: keep-all;
  font-weight: var(--pretendard-medium);
}

/* responsive */

@media all and (min-width: 2060px) {
  /* .box-container .box-left {
    left: calc(240px + 40px);
  } */

  .box-container .box-right {
    padding-left: 300px;
  }

  .box-container .box-right .content {
    justify-content: flex-start;
  }
}
@media all and (min-width: 1470px) and (max-width: 1919.98px) {
  .box-container .box-left {
    width: calc(100vw * 0.37);
  }

  .box-container .box-right {
    padding-top: 91px;
    padding-right: 80px;
    width: calc(100vw * 0.6);
  }

  .box-container .box-right .paragraph {
    max-width: 350px;
  }

  /* .box-container .box-right .paragraph:last-child {
    margin-left: 80px;
  } */

  /* .box-container .box-right .paragraph-title {
    font-size: 30px;
  }

  .box-container .box-right .paragraph:last-child {
    margin-left: 50px;
  } */
}
@media all and (min-width: 1260px) and (max-width: 1469.98px) {
  .box-container .box-right {
    padding-top: 91px;
    padding-right: 80px;
  }

  .box-container .box-right .paragraph {
    max-width: 280px;
  }

  .box-container .box-right .paragraph-title {
    font-size: 30px;
  }

  .box-container .box-right .paragraph:last-child {
    margin-left: 50px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1259.98px) {
  .box-container .box-right {
    /* top: calc(450px - 86px); */
    /* width: calc(100vw * 0.85); */
    /* height: 650px; */
    /* height: 100%; */
    height: fit-content;
    padding-bottom: 40px;
    padding-top: 40px;
    /* padding-right: calc(100% * 0.18); */
    padding-right: 80px;
  }

  .box-container .box-right .content {
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;
    /* align-items: flex-end; */
    /* left: calc(100vw * 0.5 + 100px); */
    padding-left: calc(100vw * 0.23);
  }

  .box-container .box-left {
    width: calc(100vw * 0.45);
    max-height: 450px;
  }

  .box-container .box-right .paragraph:last-child {
    margin-left: 0;
    margin-bottom: 0;
  }

  .box-container .box-right .paragraph {
    margin-bottom: 50px;
  }
}
@media all and (max-width: 1023.99px) {
  .box-container {
    height: 100%;
  }
  .box-right-container {
    height: 100%;
  }
  .box-container .box-left,
  .cpo-engineer-container .title {
    display: none;
  }

  .box-right-container,
  .box-container .box-right {
    position: static;
    width: 100%;
  }

  .box-container .box-right {
    padding-top: 0;
    height: fit-content;
  }

  .box-container .box-right .content {
    height: 100%;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 90px;
    /* flex-wrap: wrap; */
  }

  .box-container .box-right .paragraph:last-child {
    margin-left: 40px;
  }

  .box-container .box-right .paragraph {
    /* height: 270px; */
    height: fit-content;
  }

  .box-container .box-right .paragraph-title {
    margin-bottom: 5px;
  }
}
@media all and (min-width: 800px) and (max-width: 1023.98px) {
  .title-outside {
    font-size: 40px;
  }

  .box-container .box-right .paragraph-title {
    font-size: 30px;
  }
}
/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 799.98px) {
  .title-outside {
    font-size: 40px;
  }

  .box-container .box-right .paragraph-title {
    font-size: 30px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
  .title-outside {
    font-size: 36px;
  }

  .box-container .box-right .paragraph-title {
    font-size: 28px;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .title-outside {
    font-size: 30px;
  }

  .box-container .box-right .paragraph-title {
    font-size: 25px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 559.98px) {
  .title-outside {
    line-height: 1.1em;
    padding-top: 90px;
  }

  .box-container .box-right .content {
    flex-direction: column;
    margin-top: 30px;
  }

  .box-container .box-right .paragraph:last-child {
    margin-left: 0;
    height: fit-content;
  }
}
@media all and (min-width: 360px) and (max-width: 559.98px) {
}
@media all and (max-width: 359px) {
}
