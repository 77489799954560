.cpo-intro-container {
  width: 100%;
  margin-top: 79px;
}

.cpo-intro-section {
  width: 100%;
  padding: 0 100px 0 240px;
}

.cpo-intro-last-section {
  width: 100%;
  padding: 0;
}

.cpo-intro-section-no-rm {
  width: 100%;
  padding: 0 0 0 240px;
}

.two-line-title {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  font-size: 111px;
  font-weight: var(--pretendard-bold);
}

.two-line-title .first-line,
.two-line-title .second-line {
  width: 100%;
  line-height: 1.3em;
}

.two-line-title .second-line {
  text-align: right;
}

.title-outside {
  font-size: 50px;
  font-weight: var(--pretendard-bold);
  height: 100%;
  display: flex;
  padding-top: 40px;
}

.cpo-engineering .cpo-intro-section {
  background-color: #f2f2f4;
}

.cpo-cert {
  margin-bottom: 100px;
}

.cpo-banner {
  margin-bottom: 200px;
}

/* responsive */
@media all and (min-width: 2800px) {
  .cpo-intro-section-no-rm {
    padding: 0 0 0 500px;
  }
}

@media all and (min-width: 2500px) and (max-width: 2799.98px) {
  .cpo-intro-section-no-rm {
    padding: 0 0 0 400px;
  }
}

@media all and (min-width: 1960px) and (max-width: 2499.98px) {
  .cpo-intro-section-no-rm {
    padding: 0 0 0 300px;
  }
}

@media all and (min-width: 1730px) and (max-width: 1919.98px) {
  .cpo-intro-section-no-rm {
    padding: 0 0 0 150px;
  }
}

@media all and (min-width: 1470px) and (max-width: 1729.98px) {
  .cpo-intro-section-no-rm {
    padding: 0 0 0 100px;
  }
}

@media all and (min-width: 1470px) and (max-width: 1919.98px) {
  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 100px;
  }
  /* 
  .cpo-intro-section-no-rm {
    padding: 0 0 0 150px;
  } */

  .two-line-title {
    padding: 0 80px;
    font-size: 100px;
  }
}

@media all and (min-width: 1260px) and (max-width: 1469.98px) {
  .cpo-intro-container {
    margin-top: 79px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 100px;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 100px;
  }

  .two-line-title {
    padding: 0 80px;
    font-size: 90px;
  }
}

@media all and (min-width: 1024px) and (max-width: 1259.98px) {
  .cpo-intro-container {
    margin-top: 79px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 100px;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 100px;
    margin-bottom: 400px;
  }

  .two-line-title {
    padding: 0 80px;
    font-size: 70px;
  }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023.98px) {
  .cpo-intro-container {
    margin-top: 79px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 80px !important;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 80px;
  }

  .two-line-title {
    padding: 0 60px;
    font-size: 56px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
  .cpo-intro-container {
    margin-top: 60px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 60px !important;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 60px;
  }

  .two-line-title {
    padding: 0 60px;
    font-size: 45px;
  }
}
@media all and (max-width: 560px) {
  .two-line-title {
    margin: 30px auto 0 auto;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .cpo-intro-container {
    margin-top: 50px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 40px !important;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 40px;
  }

  .two-line-title {
    padding: 0 40px;
    font-size: 40px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 411px) and (max-width: 559.98px) {
  .cpo-intro-container {
    margin-top: 50px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 40px !important;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 40px;
  }

  .two-line-title {
    padding: 0 40px;
    font-size: 28px;
  }
}
@media all and (max-width: 410.98px) {
  .cpo-intro-container {
    margin-top: 50px;
  }

  .cpo-intro-section,
  .box-container .box-right {
    padding: 0 30px !important;
  }

  .cpo-intro-section-no-rm {
    padding: 0 0 0 30px;
  }

  .two-line-title {
    padding: 0 30px;
    font-size: 24px;
  }
}
