.cpo-last-section-layout {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(to top, #c1d8f3, #ffffff);
}

.cpo-last-section-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to top, #c1d8f3, #ffffff); */
  background: transparent;
  padding-left: calc(100vw * 0.125);
  padding-right: calc(100vw * 0.125);
  padding-bottom: 150px;
  margin-top: 300px;
  /* padding: 0 240px 150px 240px; */
}

.cpo-last-section-container .row-container {
  position: relative;
  z-index: 2;
}

.bottom-right-mark {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  right: 50px;
  bottom: 50px;
}

.bottom-right-mark .text {
  font-size: 20px;
  font-weight: var(--pretendard-semibold);
  color: #ffffff;
  margin-right: 10px;
  text-align: right;
  line-height: 1.1em;
}

.cpo-last-section-container img.cpo-mark-rotate {
  position: absolute;
  right: calc(100vw * 0.26);
  z-index: 1;
  animation: rotation 20s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.place-on-right .text-box {
  margin: 0 0 0 auto;
}

.cpo-last-section-container .row-container .text-box {
  width: 100%;
  max-width: 720px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 59px 76px 0 76px;
  /* z-index: 2; */
}

.cpo-last-section-container .row-container .text-box .title {
  font-size: 50px;
  font-weight: var(--pretendard-bold);
  margin-bottom: 10px;
}

.cpo-last-section-container .row-container .text-box .sub-title {
  font-size: 24px;
  font-weight: var(--pretendard-bold);
  margin-bottom: 60px;
}

.cpo-last-section-container .row-container .text-box .desc {
  font-size: 17px;
  font-weight: var(--pretendard-medium);
  margin-bottom: 20px;
}

.cpo-last-section-container .row-container .text-box .desc-important {
  font-size: 18px;
  font-weight: var(--pretendard-bold);
  word-break: keep-all;
  margin-bottom: 100px;
}

/* responsive */

@media all and (min-width: 1200px) and (max-width: 1919.98px) {
}
@media all and (max-width: 1199.98px) {
  .cpo-last-section-container {
    align-items: center;
  }
  .place-on-right .text-box {
    margin: 0;
  }
}
@media all and (min-width: 1024px) and (max-width: 1199.98px) {
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 859.98px) {
  .cpo-last-section-container {
    padding: 0 0 150px 0;
  }

  .cpo-last-section-container img.cpo-mark-rotate {
    right: 60px;
    width: 140px;
  }
}
@media all and (max-width: 560px) {
  .cpo-last-section-container {
    margin-top: 100px;
    padding-bottom: 50px !important;
  }

  .bottom-right-mark {
    right: 40px;
    bottom: 40px;
  }

  .bottom-right-mark .text {
    font-size: 15px;
    margin-right: 10px;
  }

  .bottom-right-mark img {
    width: 50px;
    height: 50px;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .cpo-last-section-container img.cpo-mark-rotate {
    right: 30px;
    width: 140px;
  }

  .cpo-last-section-container {
    padding: 0 0 150px 0;
  }

  .cpo-last-section-container .row-container .text-box .title {
    font-size: 43px;
  }

  .cpo-last-section-container .row-container .text-box .sub-title {
    font-size: 23px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 361px) and (max-width: 559.98px) {
  .cpo-last-section-container img.cpo-mark-rotate {
    right: 20px;
    width: 100px;
  }
  .cpo-last-section-container {
    padding: 0 0 150px 0;
  }

  .cpo-last-section-container .row-container .text-box {
    padding: 0 40px 0 40px;
  }

  .cpo-last-section-container .row-container .text-box .title {
    font-size: 30px;
  }

  .cpo-last-section-container .row-container .text-box .sub-title {
    font-size: 20px;
    margin-bottom: 50px;
  }

  .cpo-last-section-container .row-container .text-box .desc {
    font-size: 17px;
  }

  .cpo-last-section-container .row-container .text-box .desc-important {
    font-size: 18px;
  }
}
@media all and (max-width: 360.98px) {
  .cpo-last-section-container img.cpo-mark-rotate {
    right: 20px;
    width: 100px;
  }
  .cpo-last-section-container {
    padding: 0 0 150px 0;
  }
  .cpo-last-section-container .row-container .text-box {
    padding: 0 40px 0 40px;
  }

  .cpo-last-section-container .row-container .text-box .title {
    font-size: 28px;
  }

  .cpo-last-section-container .row-container .text-box .sub-title {
    font-size: 18px;
    margin-bottom: 50px;
  }

  .cpo-last-section-container .row-container .text-box .desc {
    font-size: 17px;
  }

  .cpo-last-section-container .row-container .text-box .desc-important {
    font-size: 18px;
  }
}
