.notice-modal {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1;
}

.modal-body {
  width: 100%;
  max-width: 450px;
  height: fit-content;
  padding-bottom: 40px;

  background-color: #fff;
}

.modal-body img {
  width: 100%;
}

.modal-body .text-box {
  background-color: #fff;
  padding: 40px;

  font-size: 15px;
  color: #333333;
  text-align: center;
}

.modal-body .text-box .title {
  font-weight: var(--pretendard-semibold);
  margin-bottom: 20px;
}

.modal-body .text-box .desc {
  margin-bottom: 25px;
}

.modal-body .text-box .benefit {
  color: #a8a8a8;
  font-size: 13px;
}

.modal-body .close-btn {
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 20px;
}

.modal-body .close-btn button {
  width: 130px;
  height: 43px;

  background-color: #fff;
  box-shadow: none;
  border: 0.8px solid #0f0f0f;

  font-size: 14px;

  cursor: pointer;
}

@media all and (max-width: 450px) {
  .notice-modal {
    justify-content: flex-start;
  }

  .modal-body .text-box .title {
    margin-bottom: 25px;
  }
}
