.about-container {
  overflow: hidden;
}

.scroll-indicator-container {
  position: fixed;
  width: 19px;
  left: 45px;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.scroll-indicator-container .mouse {
  position: absolute;
  bottom: 110px;
}

.scroll-indicator-container .scroll-indicator-bar {
  height: 100px;
  bottom: 0;
  z-index: 9;
  background-color: #bbb;
  position: absolute;
  left: calc(18.999px / 2 - 1px);
}

.scroll-indicator-container .scroll-indicator-bar,
.scroll-indicator-container .scroll-indicator-bar-active {
  width: 2px;
}

.scroll-indicator-container .scroll-indicator-bar-active {
  bottom: 100px;
  z-index: 1000;
  background-color: #184170;
}

/* 상단 제목 섹션 start */
.about-top-heading {
  --top-heading-height: calc(100vh - 70px);
  width: 100%;
  height: var(--top-heading-height);
  /* position: relative; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.top-heading-scrollpage {
  height: calc(100vh - 70px) !important;
  position: relative;
}

.notice-banner-sticky {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #000000;
  color: #fff;
}

.notice-banner-sticky .close-btn {
  width: 100%;
  height: 100%;

  position: relative;
}
.notice-banner-sticky .close-btn svg {
  position: absolute;
  right: 0;
  top: 0;

  cursor: pointer;
}

.notice-slider-container {
  width: 100%;
  height: 100%;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
}

.notice-content {
  text-decoration: underline;
  font-size: 15px;
  font-weight: var(--pretendard-medium);

  cursor: pointer;

  z-index: 2;
}

.about-top-heading .text-container,
.bluement-meaning-section .title {
  font-size: 111px;
  /* font-size: 5.781vw; */
  color: #333333;
  font-weight: 400;
}

.about-top-heading .text-container .slide-out-left,
.about-top-heading .text-container .slide-out-right {
  line-height: 1.28;
}

.about-top-heading .text-container .slide-out-left {
  text-align: right;
}

.about-top-heading .text-container .first-line {
  padding-right: calc(100vw * 0.024);
}

.about-top-heading .text-container .slide-out-right {
  text-align: left;
  padding-right: calc(100vw * 0.073);
}

.about-top-heading .text-container .bigmoore,
.bluement-meaning-section .bigmoore {
  font-family: "bigmoore", serif;
  font-style: italic;
}

.about-top-heading .text-container .last-line {
  font-size: 40px;
  text-align: right;
  padding-right: calc(100vw * 0.114);
  margin-top: 15px;
}
/* 상단 제목 섹션 end */

/* 시계 애니메이션 섹션 end */
.time-minute-section {
  width: 100%;
  height: 90vh;
  /* padding-top: calc(100vh * 0.15); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.time-minute-section .clock-animation {
  --clock-svg-width: 200px;
  width: 100%;
  height: var(--clock-svg-width);
  position: relative;
  margin-bottom: 50px;

  display: flex;
  justify-content: center;
}

.time-minute-section .clock-animation svg {
  position: absolute;
  left: calc(50% - var(--clock-svg-width) / 2);
}

.time-minute-section .clock-animation .time,
.time-minute-section .clock-animation .minute {
  position: absolute;
  left: calc(50% - var(--clock-svg-width) / 2);
  /* width: 200px;
  height: 199.999px; */
}

.time-minute-section .text-box {
  font-size: 30px;
  font-weight: var(--pretendard-semibold);
  text-align: center;
  margin-bottom: calc(100vh * 0.17);
}

.time-minute-section .our-story-btn {
  background-color: #ffffff;
  box-shadow: none;
  border: 0.5px solid #000000;
  border-radius: 100px;

  cursor: pointer;

  width: 300px;
  height: 100px;

  font-size: 25px;
  font-weight: var(--pretendard-medium);
}

.time-minute-section .time-rotate {
  transform-origin: center center;
}
/* 시계 애니메이션 섹션 end */

/* OUR STORY 섹션 start */
.story-section {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
}

.story-section .left-box,
.story-section .right-box {
  flex: 1 1 50%;
  height: 100%;
  overflow: hidden;
}

.our-story .left-box {
  background-image: url("https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/left-box.jpg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 50% auto;
  background-attachment: fixed;
  z-index: 0;
}

.story-section .left-box img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

.story-section .right-box {
  padding: calc(100vw * 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.story-section .right-box .title {
  /* font-size: 40px; */
  font-size: 2.083vw;
  font-weight: var(--pretendard-black);
  line-height: 1.25;
}

.story-section .right-box .sub-title {
  /* font-size: 30px; */
  font-size: 1.563vw;
  font-weight: var(--pretendard-bold);
  margin-bottom: 50px;
  line-height: 1.25;
}

.story-section .right-box .desc {
  /* font-size: 20px; */
  font-size: 1.042vw;
  font-weight: var(--pretendard-medium);
  /* margin-bottom: 150px; */
}

.story-section .right-box .link-box {
  font-size: 25px;
  /* font-size: 1.302vw; */
  font-weight: var(--pretendard-medium);
  color: #888888;
}

.story-section .right-box .link-box a {
  color: #888888 !important;
}

.story-section .right-box .link-box div {
  cursor: pointer;
  margin-bottom: calc(100vh * 0.05);
}

.story-section .right-box .link-box img {
  width: 70%;
}

.our-moto {
  border-top: 0.5px solid #e9e9e9;
  border-bottom: 0.5px solid #e9e9e9;
}

.our-vision {
  border-top: 0.5px solid #e9e9e9;
  border-bottom: 0.5px solid #e9e9e9;
}
/* OUR STORY 섹션 end */

/* BLUEMENT 의미 섹션 start */
.bluement-meaning-section {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  --logo-gap: 20px;
  --desc-gap: 100px;
  --bluement-meaning-padding: calc(100vw * 0.07);
  padding: var(--bluement-meaning-padding);
}

.bluement-meaning-section .text-container {
  width: 100vw;

  display: flex;
}

.bluement-meaning-section .text-container .title {
  width: 50%;
  text-align: right;
  line-height: 1.3;
}

.bluement-meaning-section .logo {
  display: flex;
  justify-content: center;
  gap: var(--logo-gap);
  align-items: center;
  margin-bottom: 83.4px;
}

.bluement-meaning-section .desc {
  display: flex;
  justify-content: center;
  gap: var(--desc-gap);

  /* font-size: 30px; */
  font-size: 1.563vw;
  font-weight: var(--pretendard-medium);
}

.bluement-meaning-section .desc div {
  max-width: 780px;
  width: calc(
    (100vw - (var(--bluement-meaning-padding) * 2) - var(--desc-gap)) / 2
  );
}
/* BLUEMENT 의미 섹션 end */

/* Store start */
.store-section {
  padding-top: 150px;
  padding-bottom: 150px;
  width: 100%;
  height: 100%;

  overflow: hidden;
}
/* 
.store-section .animated-text {
  width: 100%;
  height: 100%;

  display: flex;
  gap: 100px;
}

.store-section .animated-text img {
  animation: AnimatedText 100s linear infinite;
  height: 90px;
}

.store-section .animated-text img:hover {
  animation-play-state: paused;
}

@keyframes AnimatedText {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-450%, 0, 0);
    transform: translate3d(-450%, 0, 0);
  }
} */

.store-section .title {
  width: 100%;
  height: 90px;

  display: flex;
  justify-content: center;

  /* padding-right: 10.417vw; */
  margin-bottom: 50px;
}

.store-section .store-name {
  font-size: 30px;
  font-weight: var(--pretendard-bold);
  line-height: 1.3;
  text-align: center;

  margin-bottom: 30px;
}

.store-section .store-info {
  font-size: 20px;
  font-weight: var(--pretendard-medium);
  line-height: 1.95;
  text-align: center;
}

.store-section .store-map {
  width: 500px;
  height: 250px;

  margin: 30px auto 50px;
}

.store-section .store-button {
  width: 170px;
  height: 54px;

  margin: 0 auto;
}

.store-section .store-button button {
  width: 100%;
  height: 100%;

  font-size: 20px;
  font-weight: var(--pretendard-medium);
  color: #fff;

  background-color: #000000;

  border-radius: 27px;
  border: 0;
  cursor: pointer;
}
/* Store end */

/* Responsive */
@media all and (min-width: 1921px) {
  .story-section .right-box .title {
    font-size: 45px;
  }

  .story-section .right-box .sub-title {
    font-size: 35px;
  }

  .story-section .right-box .desc {
    font-size: 25px;
  }

  .story-section .right-box .link-box {
    font-size: 30px;
  }

  .story-section .right-box .link-box svg {
    width: 20px;
    height: 20px;
  }

  .bluement-meaning-section .desc {
    font-size: 30px;
  }

  .story-section .right-box .link-box img {
    width: fit-content;
  }
}
@media all and (max-width: 1540px) {
  .story-section .right-box .desc {
    font-size: 16px;
  }
}
/* @media all and (max-width: 1200px) {
  .store-section .title {
    height: 90px;
  }
} */
@media all and (max-width: 1000px) {
  .store-section .title {
    height: 9vw;
    /* padding-right: 0; */
    /* justify-content: center; */
  }

  /* .store-section .animated-text img {
    height: 11vw;
  } */
}
@media all and (min-width: 1400px) and (max-width: 1919.98px) {
}
@media all and (min-width: 1216px) and (max-width: 1399.98px) {
  .story-section .right-box .title {
    font-size: 28px;
  }
  .story-section .right-box .sub-title {
    font-size: 22px;
  }
  .story-section .right-box .link-box {
    font-size: 20px;
  }
  .story-section .right-box .link-box svg {
    width: 15px;
    height: 15px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 30px;
  }
  .bluement-meaning-section .desc {
    font-size: 1.53vw;
  }
}
@media all and (min-width: 1200px) and (max-width: 1215.98px) {
  .story-section .right-box .title {
    font-size: 28px;
  }
  .story-section .right-box .sub-title {
    margin-bottom: 40px;
    font-size: 22px;
  }
  .story-section .right-box .desc {
    font-size: 15px;
  }
  .story-section .right-box .link-box {
    font-size: 20px;
  }
  .story-section .right-box .link-box svg {
    width: 15px;
    height: 15px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 30px;
  }

  .bluement-meaning-section {
    --desc-gap: 60px;
  }
  .bluement-meaning-section .desc {
    font-size: 17px;
  }
}
@media all and (max-width: 1199.98px) {
  .story-section {
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .story-section .left-box,
  .story-section .right-box {
    flex: 1 1 100%;
    width: 100%;
    height: 100%;
  }

  .story-section .right-box .desc {
    margin-bottom: 100px;
  }

  .our-story .left-box {
    background-image: none;
  }

  .story-section .right-box .link-box {
    font-size: 20px;
  }

  .story-section .right-box .link-box svg {
    width: 15px;
    height: 15px;
  }

  .story-section .right-box .link-box img {
    width: 53%;
  }

  .our-story .left-box {
    background-image: url("https://amplify-bluementwebcra-dev-8f18d-deployment.s3.ap-northeast-2.amazonaws.com/public/about/left-box.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    background-attachment: fixed;
    position: relative;
  }
}
@media all and (min-width: 825px) and (max-width: 1199.98px) {
  .story-section .right-box {
    padding: 70px;
  }
  .story-section .right-box .title {
    font-size: 40px;
  }
  .story-section .right-box .sub-title {
    font-size: 30px;
  }
  .story-section .right-box .desc {
    font-size: 18px;
  }

  .bluement-meaning-section {
    --desc-gap: 60px;
  }
  .bluement-meaning-section .desc {
    font-size: 17px;
  }
}
@media all and (max-width: 1174.98px) {
  .about-top-heading .text-container {
    font-size: 9.447vw;
  }

  .about-top-heading .text-container .last-line {
    font-size: 3.404vw;
  }
}
@media all and (max-width: 824.98px) {
  .bluement-meaning-section {
    --desc-gap: 40px;
    --bluement-meaning-padding: 80px;
  }

  .bluement-meaning-section .desc {
    flex-direction: column;
    font-size: 20px;
  }

  .bluement-meaning-section .desc div {
    width: 100%;
    max-width: 590px;
  }

  .bluement-meaning-section .text-container .title {
    width: 48%;
  }

  .bluement-meaning-section .logo img.img-blue {
    width: 32.927vw;
  }
  .bluement-meaning-section .logo img.img-ment {
    width: 39.018vw;
  }

  .bluement-meaning-section .text-container .title {
    font-size: 15vw;
  }
}
@media all and (max-width: 767.98px) {
  .time-minute-section .text-box {
    margin-bottom: calc(100vh * 0.1);
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 824.98px) {
  .story-section .right-box {
    padding: 60px;
  }
  .story-section .right-box .title {
    font-size: 30px;
  }
  .story-section .right-box .sub-title {
    font-size: 24px;
  }
  .story-section .right-box .desc {
    font-size: 18px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 30px;
  }
  .story-section .right-box .link-box img {
    width: 360px;
  }
}
@media all and (max-width: 649.98px) {
  .store-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .store-section .animated-text {
    gap: 60px;
  }

  .store-section .title {
    margin-bottom: 40px;
  }

  .store-section .store-name {
    font-size: 23px;
  }

  .store-section .store-info {
    font-size: 16px;
  }

  .store-section .store-map {
    margin: 40px auto;
  }
}
@media all and (max-width: 500px) {
  .store-section .store-map {
    width: 100%;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .story-section .right-box {
    padding: 60px;
  }
  .story-section .right-box .title {
    font-size: 30px;
  }
  .story-section .right-box .sub-title {
    font-size: 24px;
  }
  .story-section .right-box .desc {
    font-size: 16px;
  }
  .story-section .right-box .link-box {
    font-size: 19px;
  }

  .story-section .right-box .link-box svg {
    width: 14px;
    height: 14px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 25px;
  }
  .story-section .right-box .link-box img {
    width: 360px;
  }

  .bluement-meaning-section {
    --bluement-meaning-padding: 70px;
  }
  .bluement-meaning-section .logo {
    margin-bottom: 80px;
  }
}
@media all and (max-width: 472.98px) {
  .store-section {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .store-section .title {
    margin-bottom: 30px;
  }

  .store-section .store-map {
    margin: 30px auto;
  }

  .time-minute-section .text-box {
    font-size: 6.356vw;
  }
  .time-minute-section .our-story-btn {
    width: 63.559vw;
    height: 21.186vw;
    font-size: 5.297vw;
  }
}

@media all and (min-width: 429px) and (max-width: 559.98px) {
  .scroll-indicator-container {
    display: none;
  }
  .about-top-heading {
    --top-heading-height: calc(50vh - 70px);
  }
  .notice-banner-sticky {
    height: 40px;
    position: sticky;
  }
  .notice-banner-sticky svg {
    width: 40px;
    height: 40px;
  }
  .top-heading-scrollpage {
    height: calc(50vh - 70px) !important;
  }
  .story-section .right-box {
    padding: 50px;
  }
  .story-section .right-box .title {
    font-size: 30px;
  }
  .story-section .right-box .sub-title {
    font-size: 23px;
  }
  .story-section .right-box .desc {
    font-size: 16px;
  }
  .story-section .right-box .link-box {
    font-size: 19px;
  }

  .story-section .right-box .link-box svg {
    width: 14px;
    height: 14px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 25px;
  }
  .story-section .right-box .link-box img {
    width: 90%;
  }

  .bluement-meaning-section {
    --bluement-meaning-padding: 60px;
    --logo-gap: 15px;
  }
  .bluement-meaning-section .logo {
    margin-bottom: 70px;
  }
}
@media all and (max-width: 390px) {
  .store-section .animated-text {
    gap: 40px;
  }

  .store-section .title,
  .store-section .animated-text img {
    height: 35px;
  }
}
@media all and (max-width: 375px) {
  .notice-banner-sticky {
    height: 40px;
    position: sticky;
  }
  .notice-content {
    font-size: 13px;
  }
  .notice-banner-sticky svg {
    width: 40px;
    height: 40px;
  }
}
@media all and (max-width: 999.98px) {
  .scroll-indicator-container {
    display: none;
  }
}
@media all and (min-width: 360px) and (max-width: 428.98px) {
  .about-top-heading {
    /* --top-heading-height: calc(50vh - 70px); */
    --top-heading-height: 370px;
  }
  .top-heading-scrollpage {
    height: calc(50vh - 70px) !important;
  }
  .story-section .right-box {
    padding: 40px;
  }
  .story-section .right-box .title {
    font-size: 26px;
  }
  .story-section .right-box .sub-title {
    font-size: 21px;
    margin-bottom: 30px;
  }
  .story-section .right-box .desc {
    font-size: 14px;
  }
  .story-section .right-box .link-box {
    font-size: 17px;
  }
  .story-section .right-box .link-box svg {
    width: 12px;
    height: 12px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 10px;
  }
  .story-section .right-box .link-box img {
    width: 100%;
  }

  .bluement-meaning-section {
    --bluement-meaning-padding: 50px;
    --logo-gap: 10px;
  }
  .bluement-meaning-section .desc {
    font-size: 18px;
  }
  .bluement-meaning-section .logo {
    margin-bottom: 70px;
  }
}
@media all and (max-width: 359.98px) {
  .scroll-indicator-container {
    display: none;
  }
  .about-top-heading {
    --top-heading-height: calc(50vh - 70px);
  }

  .top-heading-scrollpage {
    height: calc(50vh - 70px) !important;
  }
  .story-section .right-box {
    padding: 40px;
  }
  .story-section .right-box .title {
    font-size: 25px;
  }
  .story-section .right-box .sub-title {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .story-section .right-box .desc {
    font-size: 15px;
  }
  .story-section .right-box .link-box {
    font-size: 17px;
  }
  .story-section .right-box .link-box svg {
    width: 12px;
    height: 12px;
  }
  .story-section .right-box .link-box div {
    margin-bottom: 10px;
  }
  .story-section .right-box .link-box img {
    width: 100%;
  }

  .bluement-meaning-section {
    --bluement-meaning-padding: 40px;
    --logo-gap: 10px;
  }
  .bluement-meaning-section .desc {
    font-size: 18px;
  }
  .bluement-meaning-section .logo {
    margin-bottom: 70px;
  }
}
