.visit-boutique {
  background-color: white !important;
} /* 타원 버튼 start */
.oval-btn-lg {
  text-align: center;
  margin-top: 60px;
}

.oval-btn-lg button {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  border-radius: 31px;
  border: none;
  background-color: #333333;
  color: #fff;
  font-size: 25px;
  font-weight: var(--pretendard-medium);
  text-align: center;
  cursor: pointer;
  width: 100%;
  max-width: 356px;
  height: 62px;
  max-height: 62px;
}

.oval-btn-lg button:hover {
  background: var(--point-color);
}
/* 타원 버튼 end */

.google-map-section {
  width: 100%;
  height: 300px;
  max-width: 800px;
  max-height: 300px;
  margin: 0 auto;
}

.visit-boutique .section-sub-title {
  margin-bottom: 50px;
}

.visit-boutique .section-title {
  margin-bottom: 10px;
}
