.cpo-banner-container {
  width: 100%;
  max-width: 1500px;
  max-height: 450px;
  overflow: hidden;
  margin: 100px auto;
}

.cpo-banner-container .cpo-banner__left {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}

.cpo-banner-container .cpo-banner__right {
  width: 100%;
  height: 100%;
  padding: 79px 0 0 60px;
}

.cpo-banner__right .text-box {
  width: 100%;
  max-width: 568px;
}

.cpo-banner__right .title {
  font-size: 50px;
  font-weight: var(--pretendard-bold);
}

.cpo-banner__right .sub-title {
  font-size: 23px;
  font-weight: var(--pretendard-bold);
  color: #999999;
  margin-bottom: 60px;
}

.cpo-banner__right .description {
  font-size: 17px;
  font-weight: var(--pretendard-medium);
}

.cpo-banner__right .description-point {
  font-size: 17px;
  font-weight: var(--pretendard-bold);
  margin-top: 20px;
}

/* responsive */

@media all and (min-width: 1470px) and (max-width: 1919.98px) {
}
@media all and (min-width: 1260px) and (max-width: 1469.98px) {
  .cpo-banner-container .cpo-banner__right {
    padding: 60px 0 0 60px;
  }

  .cpo-banner__right .title {
    font-size: 45px;
  }

  .cpo-banner__right .sub-title {
    font-size: 24px;
    margin-bottom: 50px;
  }
}
@media all and (min-width: 1024px) and (max-width: 1259.98px) {
  .cpo-banner-container .cpo-banner__right {
    padding: 40px 0 0 50px;
  }

  .cpo-banner__right .title {
    font-size: 40px;
  }

  .cpo-banner__right .sub-title {
    font-size: 20px;
    margin-bottom: 50px;
  }

  .cpo-banner__right .description {
    font-size: 17px;
  }

  .cpo-banner__right .description-point {
    font-size: 17px;
    margin-top: 20px;
  }
}
@media all and (min-width: 993px) and (max-width: 1023.98px) {
  .cpo-banner-container {
    max-height: none;
    margin: 100px auto;
  }

  .cpo-banner-container .cpo-banner__right {
    padding: 10px 0 0 60px;
  }

  .cpo-banner__right .text-box {
    width: 100%;
    max-width: none;
  }
}
/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 992.98px) {
  .cpo-banner-container {
    max-height: none;
    margin: 100px auto;
  }

  .cpo-banner-container .cpo-banner__right {
    padding: 30px 60px 0 60px;
  }

  .cpo-banner__right .text-box {
    width: 100%;
    max-width: none;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767.98px) {
  .cpo-banner-container {
    max-height: none;
    margin: 80px auto;
  }

  .cpo-banner-container .cpo-banner__right {
    padding: 60px 60px 0 60px;
  }

  .cpo-banner__right .text-box {
    width: 100%;
    max-width: none;
  }
}
@media all and (max-width: 560px) {
  .cpo-banner-container .cpo-banner__left {
    height: 250px;
    width: auto;
  }

  .cpo-banner-container .cpo-banner__left img {
    height: 100%;
  }
  .cpo-banner__right .sub-title {
    margin-bottom: 20px;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .cpo-banner-container {
    max-height: none;
    /* margin: 80px auto; */
  }

  .cpo-banner-container .cpo-banner__right {
    padding: 50px 40px 0 40px;
  }

  .cpo-banner__right .text-box {
    width: 100%;
    max-width: none;
  }

  .cpo-banner__right .title {
    font-size: 40px;
  }

  .cpo-banner__right .sub-title {
    font-size: 24px;
    margin-bottom: 50px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 360px) and (max-width: 559.98px) {
  .cpo-banner-container {
    max-height: none;
    margin-top: 30px;
  }

  .cpo-banner-container .cpo-banner__right {
    padding: 40px 20px 0 20px;
  }

  .cpo-banner__right .text-box {
    max-width: none;
  }

  .cpo-banner__right .title {
    font-size: 40px;
  }

  .cpo-banner__right .sub-title {
    font-size: 23px;
    /* margin-bottom: 50px; */
  }
}
@media all and (max-width: 359.98px) {
  .cpo-banner-container {
    max-height: none;
    margin-top: 30px;
  }

  .cpo-banner-container .cpo-banner__right {
    padding: 40px 20px 0 20px;
  }

  .cpo-banner__right .text-box {
    max-width: none;
  }

  .cpo-banner__right .title {
    font-size: 40px;
  }

  .cpo-banner__right .sub-title {
    font-size: 23px;
    /* margin-bottom: 50px; */
  }
}
