.payback-layout {
  padding-top: 80px;
}

.payback-layout .page-title-container {
  margin-bottom: 30px;
}

.page-title-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.cpo-payback-container {
  margin: 0 auto;
}

/* payback2 section */
.payback-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 80px;
  /* margin: 0 auto; */
}

.payback-container .payback-top-box {
  width: 100%;
  max-width: 1000px;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 47px 60px;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  margin-bottom: 100px;
  gap: 20px;
  /* margin: 0 auto 100px auto; */
  /* flex-wrap: wrap; */
  color: var(--dark-gray-color);
}

.payback-top-box .left-content {
  width: 100%;
  max-width: 454px;
}

.payback-top-box .right-content {
  font-weight: var(--pretendard-medium);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.payback-top-box .right-content .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.payback-top-box .right-content .button-container button {
  width: 160px;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #707070;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.payback-benefit-container {
  width: 100%;
  max-width: 1000px;
  margin-bottom: 150px;
}

.payback-benefit-container .title {
  font-size: 32px;
  font-weight: var(--pretendard-medium);
  margin-bottom: 29px;
  color: var(--dark-gray-color);
}

.payback-benefit-box {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 40px;
}

.payback-benefit-box .ant-divider {
  border: 1px solid #f5f5f5;
  margin: 0;
}

.payback-benefit {
  display: flex;
  align-items: center;
  /* animation: reveal 0.5s forwards var(--delay); */
}

.payback-benefit img {
  margin-right: 50px;
}

.payback-benefit .benefit-title {
  font-size: 26px;
  font-weight: var(--pretendard-bold);
  margin-bottom: 7px;
}

.payback-benefit .benefit-desc {
  font-size: 15px;
  color: var(--dark-gray-color);
  word-wrap: break-word;
  word-break: keep-all;
}

.payback-benefit-container .desc-footer {
  color: #888888;
  font-size: 15px;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.payback-last-section {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #c1d8f3, #ffffff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 127px 100px;
}

.payback-last-section .title {
  font-size: 40px;
  font-weight: var(--pretendard-bold);
  margin-bottom: 25px;
  color: #777777;
  width: 100%;
  max-width: 1000px;
  text-align: center;
}

.payback-last-section .ant-divider {
  width: 350px;
  border: 1px solid #fff;
  margin: 0;
  margin-bottom: 25px;
  min-width: 350px;
}

.payback-last-section .white-sub-title {
  font-size: 25px;
  font-weight: var(--pretendard-semibold);
  color: #fff;
  text-align: center;
  /* margin-bottom: 25px; */
}

/* payback section */
.payback-section-container {
  width: 100%;
  max-width: 980px;
  display: flex;
  align-items: center;
  margin: 0 auto 150px auto;
}

.payback-layout .section-sub-title {
  font-size: 20px !important;
  margin-bottom: 30px;
}

.payback-section-container .left-container,
.payback-section-container .right-container {
  flex: 1 1 50%;
}

.payback-section-container .left-container {
  padding: calc(50% * 0.155) calc(50% * 0.147) calc(50% * 0.155)
    calc(50% * 0.147);
}

.payback-section-container .left-container .title {
  color: #888888;
  font-size: 28px;
  font-weight: var(--pretendard-bold);
  margin-bottom: 20px;
}

.payback-section-container .left-container .desc {
  font-size: 17px;
  color: var(--dark-gray-color);
  margin-bottom: 30px;
}

.payback-section-container .detail-box {
  background-color: #f9f9f9;
  border-radius: 15px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-bottom: 50px;
  font-size: 16px;
}

.payback-section-container .detail-box .bullet {
  font-size: 17px;
}

.payback-section-container .detail-box .bullet-desc {
  margin-bottom: 20px;
}

.payback-section-container .detail-box .bullet-desc:last-child {
  margin-bottom: 8px;
}

.payback-section-container .detail-box .text-link {
  margin-bottom: 18px;
  font-size: 17px;
}

.payback-section-container .right-container {
  display: flex;
  justify-content: center;
}

.payback-section-container .right-container img {
  width: 95% !important;
  height: auto !important;
}

.payback-section-container .table-desc {
  color: #888888;
  font-size: 12px;
  /* margin-bottom: 70px; */
}

.payback-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payback-btn a {
  width: 100%;
  max-width: 160px;
  flex: 1 1 50%;
}

.payback-btn button {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  border-radius: 33px;
  border: none;
  background-color: var(--point-color);
  color: #fff;
  font-size: 17px;
  font-weight: var(--pretendard-medium);
  text-align: center;
  cursor: pointer;
  width: 100%;
  /* max-width: 160px; */
  height: 47px;
}
/* 
.payback-btn button:hover {
  background: var(--point-color);
} */

.payback-section-container .other-models {
  font-size: 17px;
}

.payback-section-container .other-models div:first-child {
  margin-bottom: 11px;
}

.payback-section-container .other-models div:last-child {
  color: var(--point-color);
}

/* Responsive */
@media all and (min-width: 1024px) and (max-width: 1215.98px) {
  .payback-container {
    padding: 0 80px;
  }

  .payback-container .payback-top-box {
    padding: 47px;
  }

  .payback-top-box .left-content {
    max-width: 430px;
  }
}
/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 818px) {
  /* .payback-top-box .right-content .button-container {
    flex-direction: column;
  } */
  .payback-container .payback-top-box {
    flex-direction: column;
  }

  .payback-top-box .right-content {
    /* flex-direction: row; */
    align-items: center;
    width: 100%;
  }

  /* .payback-top-box .right-content .button-container button {
    width: calc((100vw - 244px) / 4);
    min-width: 100%;
  } */
}
@media all and (min-width: 768px) and (max-width: 1023.98px) {
  .payback-container {
    padding: 0 60px;
  }

  .payback-container .payback-top-box {
    padding: 47px 47px;
    /* gap: 20px; */
  }

  .payback-top-box .left-content {
    max-width: none;
  }

  /* .payback-top-box .right-content .button-container button {
    width: 130px;
  } */

  .payback-section-container .left-container {
    flex: 1 1 55%;
  }

  .payback-section-container .right-container {
    flex: 1 1 45%;
  }

  .page-title-container .section-title {
    font-size: 35px;
  }

  .page-title-container .section-title-lg {
    font-size: 52px;
  }

  .payback-layout .section-sub-title {
    font-size: 19px !important;
  }

  .payback-section-container .right-container img {
    width: 90% !important;
  }

  .payback-btn a {
    width: 100%;
    max-width: 160px;
    flex: 0 1 47%;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767.98px) {
  .payback-container .payback-top-box {
    padding: 47px 47px;
    gap: 20px;
  }

  .payback-top-box .left-content {
    max-width: none;
  }

  .payback-section-container .right-container {
    display: flex;
    justify-content: center;
  }

  .payback-section-container .right-container img {
    width: 100% !important;
  }

  .payback-section-container {
    flex-direction: column;
  }

  .payback-layout .section-title {
    margin-bottom: 8px !important;
  }
  .payback-btn a {
    width: 100%;
    max-width: 160px;
    flex: 0 1 47%;
  }
}
@media all and (max-width: 700px) {
  .payback-top-box .right-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .payback-top-box .right-content .button-container button {
    width: 160px;
  }
}
@media all and (max-width: 649.98px) {
  .payback-section-container {
    margin-bottom: 50px;
  }
}
@media all and (max-width: 580px) {
  .payback-top-box .right-content .button-container button {
    width: calc((100vw - 230px) / 2);
    min-width: 100%;
    /* width: calc((100%-30px) / 4); */
  }
}
@media all and (max-width: 560px) {
  .payback-layout {
    padding-top: 10px;
  }
}
@media all and (min-width: 650px) and (max-width: 767.98px) {
  .payback-container {
    padding: 0 60px;
  }

  .payback-last-section {
    padding: 127px 80px;
  }

  .payback-section-container {
    padding: 0 80px;
  }

  .page-title-container .section-title {
    font-size: 32px;
  }

  .page-title-container .section-title-lg {
    font-size: 49px;
  }

  .payback-layout .section-sub-title {
    font-size: 18px !important;
  }
}
@media all and (min-width: 560px) and (max-width: 649.98px) {
  .payback-container {
    padding: 0 50px;
  }

  .payback-section-container {
    padding: 0 50px;
  }

  .payback-last-section {
    padding: 127px 50px;
  }

  .payback-last-section .title {
    font-size: 38px;
  }

  .page-title-container .section-title {
    font-size: 27px;
  }

  .page-title-container .section-title-lg {
    font-size: 40px;
  }

  .payback-layout .section-sub-title {
    font-size: 16px !important;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 519px) and (max-width: 559.98px) {
  .payback-container {
    padding: 0 50px;
  }
  .payback-section-container {
    padding: 0 50px;
  }

  .payback-benefit img {
    width: 90px;
    height: 90px;
    margin-right: 40px;
  }

  .payback-last-section {
    padding: 127px 50px;
  }

  .payback-last-section .title {
    font-size: 35px;
  }

  .page-title-container .section-title {
    font-size: 27px;
  }

  .page-title-container .section-title-lg {
    font-size: 38px;
  }

  .payback-layout .section-sub-title {
    font-size: 16px !important;
  }
}
@media all and (min-width: 429px) and (max-width: 518.98px) {
  .payback-container {
    padding: 0 50px;
  }
  .payback-section-container {
    padding: 0 50px;
  }

  .payback-benefit img {
    width: 90px;
    height: 90px;
    margin-right: 30px;
  }

  .payback-benefit .benefit-title {
    font-size: 20px;
  }

  .payback-last-section {
    padding: 127px 50px;
  }

  .payback-last-section .title {
    font-size: 29px;
    word-wrap: break-word;
    word-break: keep-all;
  }

  .payback-last-section .ant-divider {
    width: 100%;
  }

  .payback-last-section .white-sub-title {
    font-size: 23px;
  }

  .page-title-container .section-title {
    font-size: 23px;
  }

  .page-title-container .section-title-lg {
    font-size: 32px;
  }

  .payback-layout .section-sub-title {
    font-size: 16px !important;
  }

  .payback-layout .section-title-lg {
    margin-bottom: 30px !important;
  }
}
@media all and (min-width: 360px) and (max-width: 428.98px) {
  .payback-container .payback-top-box {
    padding: 40px;
  }

  .payback-container {
    padding: 0 25px;
  }

  .payback-top-box .right-content .button-container button {
    width: calc((100vw - 140px) / 2);
    min-width: 100%;
  }

  .payback-benefit-container {
    margin-bottom: 70px;
  }

  .payback-benefit img {
    width: 80px;
    height: 80px;
    margin-right: 30px;
  }

  .payback-benefit .benefit-title {
    font-size: 20px;
  }

  .payback-section-container {
    padding: 0 25px;
  }

  .payback-last-section {
    padding: 70px 25px;
  }

  .payback-last-section .title {
    font-size: 25px;
    word-wrap: break-word;
    word-break: keep-all;
  }

  .payback-last-section .ant-divider {
    width: 100%;
    min-width: 100%;
  }

  .payback-last-section .white-sub-title {
    font-size: 20px;
  }

  .page-title-container .section-title {
    font-size: 20px;
  }

  .page-title-container .section-title-lg {
    font-size: 27px;
  }

  .payback-layout .section-sub-title {
    font-size: 16px !important;
  }

  .payback-layout .section-title {
    margin-bottom: 8px !important;
  }

  .payback-layout .section-title-lg {
    margin-bottom: 30px !important;
  }

  .payback-section-container .left-container .title {
    font-size: 25px;
  }
}
@media all and (max-width: 359.98px) {
  .payback-container .payback-top-box {
    padding: 40px;
  }
  .payback-container {
    padding: 0 20px;
  }

  .payback-top-box .right-content .button-container button {
    width: calc((100vw - 144px) / 2);
    min-width: 100%;
  }

  .payback-benefit-container {
    margin-bottom: 70px;
  }

  .payback-benefit img {
    width: 80px;
    height: 80px;
    margin-right: 30px;
  }

  .payback-benefit .benefit-title {
    font-size: 20px;
  }

  .payback-last-section {
    padding: 70px 20px;
  }

  .payback-last-section .title {
    font-size: 25px;
    word-wrap: break-word;
    word-break: keep-all;
  }

  .payback-last-section .ant-divider {
    width: 100%;
    min-width: 100%;
  }

  .payback-last-section .white-sub-title {
    font-size: 20px;
  }

  .payback-section-container {
    padding: 0 20px;
  }

  .page-title-container .section-title {
    font-size: 18px;
  }

  .page-title-container .section-title-lg {
    font-size: 25px;
  }

  .payback-layout .section-sub-title {
    font-size: 15px !important;
  }

  .payback-layout .section-title {
    margin-bottom: 8px !important;
  }

  .payback-layout .section-title-lg {
    margin-bottom: 30px !important;
  }
}
