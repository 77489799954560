/* Common start */
.circledCard-container {
  width: 1352px;
  max-width: 1352px;
  background-color: transparent;
  align-content: center;
  justify-items: center;
  margin: 0 auto !important;
  /* column-gap: 80px; */
}

.circledCard {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 362px;
  max-width: 362px;
  /* max-height: 470px; */
  background-color: transparent;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.circledCard-thumbnail {
  width: 300px;
  height: 300px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid #d0d0d0;
}

.circledCard-thumbnail img {
  width: 100%;
  height: auto;
  /* height: 100%; */
  object-fit: cover;
}

.circledCard-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.circledCard-content .card-title {
  font-size: 26px;
  margin-top: 47px;
  word-wrap: break-word;
  word-break: keep-all;
}

.circledCard-content .card-desc {
  margin-top: 25px;
  padding: 0 10px;
  font-size: 18px;
  word-wrap: break-word;
  word-break: keep-all;
}
/* Common end */

/* Trade Watch start */
/* Trade Watch end */

/* Bespoke start */
/* Bespoke end */

/* 반응형 */
/* 모바일 세로 화면 2 */
@media (max-width: 359.99px) {
  .circledCard-container {
    width: 100%;
  }

  .circledCard {
    width: 250px;
  }

  .circledCard-thumbnail {
    width: 200px;
    height: 200px;
  }
}

/* 모바일 세로 화면 1 */
@media (min-width: 360px) and (max-width: 575.99px) {
  .circledCard-container {
    width: 100%;
  }
  .circledCard {
    width: 250px;
  }

  .circledCard-thumbnail {
    width: 200px;
    height: 200px;
  }
}

/* 모바일 가로 화면 2 */
@media (min-width: 576px) and (max-width: 649.99px) {
  .circledCard-container {
    width: 100%;
  }
  .circledCard {
    width: 350px;
  }

  .circledCard-thumbnail {
    width: 250px;
    height: 250px;
  }
}

/* 모바일 가로 화면 1 */
@media (min-width: 650px) and (max-width: 767.98px) {
  .circledCard-container {
    width: 100%;
  }
  .circledCard {
    width: 350px;
  }

  .circledCard-thumbnail {
    width: 250px;
    height: 250px;
  }
}

/* 태블릿(갤럭시 탭, 아이패드) 화면 */
@media (min-width: 768px) and (max-width: 991.98px) {
  .circledCard-container {
    width: 100%;
  }
}

/* PC 화면 1200px 이하 */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* 코드 작성 */
  .circledCard-container {
    width: 820px;
  }
  .circledCard {
    width: 250px;
  }

  .circledCard-thumbnail {
    width: 200px;
    height: 200px;
  }

  .circledCard-content .card-title {
    font-size: 23px;
  }

  .circledCard-content .card-desc {
    font-size: 15px;
  }
}

/* PC 화면 1200px 이상 1920px 이하 */
@media (min-width: 1200px) and (max-width: 1599.99px) {
  .circledCard-container {
    width: 1000px;
  }
  .circledCard {
    width: 300px;
  }

  .circledCard-thumbnail {
    width: 240px;
    height: 240px;
  }
}

/* PC 화면 1200px 이상 1920px 이하 */
@media (min-width: 1600px) and (max-width: 1919.99px) {
  .circledCard {
    width: 330px;
  }

  .circledCard-thumbnail {
    width: 260px;
    height: 260px;
  }
}
