.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 17.6px;
  z-index: 999;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.fa-bars {
  color: #000000;
}

.menu-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  cursor: pointer;
}

.menu-icon .open-btn {
  color: #000000 !important;
  width: 20px;
  height: 11px;
}

.navbar-logo-left {
  margin: auto 0;
  float: left;
}

.navbar-logo-left img {
  width: 180px;
  margin: auto 0;
}

.menu-group {
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  list-style: none;
  text-align: center;
  width: 100%;
  border: none;
}

.navbar-menu-container {
  position: relative;
  border-bottom: 0 !important;
  height: 70px;
  width: 100%;
  margin-left: 20px;
}

.navbar-padding {
  -webkit-padding-start: 50px;
  padding-inline-start: 50px;
  padding-inline-end: 50px;
  -webkit-padding-end: 50px;
}

.nav-menu {
  height: 70px;
  display: flex;
  margin: 0;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
}

.dropdown-bg {
  position: absolute;
  padding-top: 20px;
  top: 70px;
  left: 0;
  width: 100%;
  height: 230px;
  background-color: #fcfcfc;
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #aaaaaa;
  z-index: 999;
}

.nav-item {
  margin: 0;
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.menu-group ul.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  width: 100%;
  height: 250px;
  list-style: none;
  z-index: 1000;
}

.menu-group ul.dropdown .nav-links {
  padding: 0;
  /* justify-content: center; */
  /* text-align: center; */
  padding-left: 20px;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  height: 50px;
  width: 100%;
}

ul.dropdown .nav-links:hover {
  /* color: var(--point-color); */
  color: #1d1b1c;
  border-bottom: 0 !important;
}

.menu-group ul.dropdown .nav-links:first-child {
  margin-top: 10px;
}

.nav-links {
  font-size: 17.6px;
  color: #1d1b1c;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 25px 20px;
  height: 100%;
}

.nav-links:hover {
  /* color: var(--point-color); */
  color: #1d1b1c;
  border-bottom: 3px solid var(--point-color);
}

.menu-group ul.dropdown li {
  white-space: nowrap;
  cursor: pointer;
}

.menu-group .activated {
  /* color: var(--point-color); */
  border-bottom: 3px solid var(--point-color);
}

/* Mobile Drawer start */
.ant-drawer-content-wrapper {
  width: 274px !important;
  transition: ease-in 0.3s !important;
}

.ant-drawer-body {
  padding: 10px 30px 0 30px;
  /* max-height: 652px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-drawer-header {
  padding: 0;
  border: none;
}

.ant-drawer-header-title {
  justify-content: flex-end;
}

.ant-drawer-close {
  display: inline-block;
  margin-right: 0;
  line-height: 50px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
  width: 50px;
  height: 70px;
  padding: 0;
}

.ant-drawer-close svg {
  width: 17px;
  height: 17px;
}

/* Mobile Menu start */
.nav-mobile-menu {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  flex-direction: column;
}

/* .nav-mobile-item, */
.nav-mobile-item__row {
  /* overflow: hidden; */
  width: 100%;
  margin: 0;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.nav-mobile-item ul.submenu-active {
  /* height: 130px; */
  height: 100%;
  overflow: hidden;
  transition: height 0.3s ease;
}

.nav-mobile-item ul.submenu-not-active {
  overflow: hidden;
  height: 0px;
  transition: height 0.3s ease;
}

.nav-mobile-subitem {
  width: 100%;
  margin: 0;
  height: 31px;
  line-height: 2em;
  font-size: 18px;
  padding-left: 17px;
  position: relative;
  cursor: pointer;
}

.nav-mobile-item-alone__row {
  width: 100%;
  margin: 0;
  height: 42px;
  position: relative;
  cursor: pointer;
}
.nav-mobile-item-alone__row .left {
  width: 100%;
  height: 100%;
}

.nav-mobile-item__row .right svg {
  width: 16.5px;
  height: 9.02px;
}

.nav-mobile-links {
  font-size: 20px;
  color: #1d1b1c;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: var(--pretendard-semibold);
  height: 100%;
}

/* Mobile Menu end */

.ant-drawer-footer {
  border: none;
  padding: 20px 40px;
}

.mobile-drawer .sns-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 20px;
}

.mobile-drawer .sns-container .sns-icon {
  color: #1d1b1c;
}

.mobile-drawer .sns-container span {
  margin: 0 14px;
  color: #cacaca;
}

/* responsive */
@media all and (min-width: 900) {
  .navbar-padding {
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}

@media all and (max-width: 899.98px) {
  .navbar-padding {
    -webkit-padding-start: 14px;
    padding-inline-start: 14px;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
  }
}
