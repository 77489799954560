@import '~antd/dist/antd.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Fonts */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/variable/pretendardvariable-dynamic-subset.css');
@import url('https://use.typekit.net/oli0nyo.css'); /* Adobe Fonts - Nimbus Roman */

:root {
  --main-bg-color: #fff;
  --rolex-color: #006039;
  --rolex-shadow-color: rgba(0, 96, 57, 0.2);
  /* --point-color: #06569d; */
  --point-color: #184170;
  --point-shadow-color: rgba(6, 86, 157, 0.4);
  --thin-line-color: #999;
  --black-color: #1d1b1c;
  --dark-gray-color: #333333;
  --pretendard-thin: 100;
  --pretendard-extralight: 200;
  --pretendard-light: 300;
  --pretendard-regular: 400;
  --pretendard-medium: 500;
  --pretendard-semibold: 600;
  --pretendard-bold: 700;
  --pretendard-extrabold: 800;
  --pretendard-black: 900;
}

* {
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}

html {
  --antd-wave-shadow-color: rgba(139, 139, 139, 1);
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #1d1b1c;
}

.text-bold {
  font-weight: var(--pretendard-bold) !important;
}

.text-medium {
  font-weight: var(--pretendard-medium) !important;
}

.txt-dark-gray {
  color: var(--dark-gray-color) !important;
}

.text-link a {
  color: var(--point-color) !important;
}

.flex-row {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.loading-container {
  height: 100vh;
  margin-top: 40vh;
  /* padding: 30px 50px; */
  text-align: center;
}

.loading-container .ant-spin-lg .ant-spin-dot {
  font-size: 50px;
}

.loading-container .ant-spin-lg .ant-spin-dot i {
  width: 25px;
  height: 25px;
}

.loading-container .ant-spin-dot {
  width: 1.2em;
  height: 1.2em;
}

.loading-container .ant-spin-dot-item {
  background-color: var(--point-color);
}

/* 배경 있는 섹션 start */
.section-w-bg {
  width: 100%;
  height: 100%;
  /* height: 834px; */
  background-color: #f5f5f5;
  padding: 90px 195px;
}
/* 배경 있는 섹션 end */

/* 타원 버튼 start */
.oval-btn-lg {
  text-align: center;
  margin-top: 60px;
}

.oval-btn-lg button {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  border-radius: 31px;
  border: none;
  background-color: #333333;
  color: #fff;
  font-size: 25px;
  font-weight: var(--pretendard-medium);
  text-align: center;
  cursor: pointer;
  width: 100%;
  max-width: 356px;
  height: 62px;
  max-height: 62px;
}

.oval-btn-lg button:hover {
  background: var(--point-color);
}
/* 타원 버튼 end */

/* 네모 버튼 start */
.square-btn-lg {
  text-align: center;
}

.square-btn-lg button {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  border-radius: 3px;
  border: none;
  background-color: #333333;
  color: #fff;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 50px;
}

.square-btn-lg button:hover {
  background: var(--point-color);
}
/* 네모 버튼 end */

.card-title {
  width: 100%;
  font-size: 25px;
  font-weight: var(--pretendard-medium);
  color: #333333;
}

.card-desc {
  width: 100%;
  font-size: 17px;
  color: #333333;
}

.red-txt {
  color: red;
}

.point_txt {
  color: var(--point-color);
  font-weight: var(--pretendard-semibold);
}

.section-title__point {
  color: var(--point-color);
}
.section-container {
  padding: 0 195px;
}

.section-gap,
.section-start-gap {
  margin-bottom: 100px;
}

.section-gap-60 {
  margin-bottom: 60px;
}

.section-gap-150 {
  margin-bottom: 150px;
}

.section-gap-200 {
  margin-bottom: 200px;
}

.ml-30 {
  margin-left: 30px;
}

.no-mb {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px;
}
.mb-24 {
  margin-bottom: 24px;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-100 {
  margin-bottom: 100px;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.h3-style {
  margin-top: 0;
  font-weight: var(--pretendard-semibold);
  margin-bottom: 32px;
  font-size: 36.8px;
  line-height: 44px;
  letter-spacing: 0;
}
.sub-heading {
  font-size: 12px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
}

.hidden {
  display: none !important;
}

.float-right {
  float: right;
}

.home,
.about,
.store,
.section-carousel,
.store-section-carousel {
  display: flex;
  flex-direction: row;
  /* height: calc(100vh - 130px); */
  height: calc(100vh - 70px);
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background-color: #1d1b1c;
  color: #fff;
}

.ant-layout {
  background-color: #fff;
}

.home-section,
.collection-section-pd {
  padding-left: 80px;
  padding-right: 80px;
}

.home-section {
  padding-top: 60px;
}

.form-title {
  margin-bottom: 0 !important;
}

.section-title-lg {
  font-size: 64px;
  font-weight: var(--pretendard-medium);
  margin-bottom: 30px;
  line-height: 1.2;
  text-align: center;
  word-break: keep-all;
}

.section-title {
  font-size: 40px;
  font-weight: var(--pretendard-medium);
  margin-bottom: 17px;
  text-align: center;
  word-break: keep-all;
}

.section-sub-title {
  font-size: 20px;
  margin-bottom: 100px;
  text-align: center;
  word-break: keep-all;
}

.section-carousel {
  margin-bottom: 50px;
}

.text-center {
  text-align: center;
}

.bottomline {
  border-bottom: 2px solid #1d1b1c;
}

a {
  color: #1d1b1c;
}

/* a:hover, */
/* a::after,
a:focus {
  color: var(--point-color);
} */

a:hover {
  color: #1d1b1c;
}

ul {
  padding-inline-start: 0;
}

.align-text-center {
  text-align: center;
}

.section {
  width: 100%;
  height: 100%;
  /* padding-top: 56.25%; */
  /* padding: 50px 80px 80px 80px; */
}

/* .section-title {
  color: white;
  text-align: center;
  margin-bottom: 50px !important;
} */

.first-section-container {
  margin-top: 100px;
}

.form-section-title h2 {
  font-weight: var(--pretendard-semibold);
}

.section-title-img {
  display: block;
  margin: auto;
  margin-bottom: 80px !important;
  /* margin-top: 100px; */
  height: 50px;
}

.ant-btn:focus,
.ant-btn:hover {
  color: #fff;
  background: var(--point-color);
  outline: transparent;
  border-color: var(--point-color);
}

.ant-card-meta-title,
.ant-card-meta-description {
  color: #1d1b1c;
}

.ant-card-meta-title {
  font-weight: var(--pretendard-extrabold);
  font-size: 20px;
}

.ant-card-meta-description {
  font-weight: var(--pretendard-semibold);
  color: '#c7c7c7';
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  /* .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 700px);
  } */
  .home-section {
    padding: 40px 60px 20px 60px;
  }

  .section-title-img {
    height: 35px;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767px) {
  .home-section {
    padding: 40px 60px 20px 60px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 500px);
  }
  .section-title-img {
    height: 35px;
  }
}

@media all and (min-width: 560px) and (max-width: 649px) {
  .home-section {
    padding: 40px 60px 20px 60px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 500px);
  }
  .section-title-img {
    height: 35px;
  }
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 360px) and (max-width: 559px) {
  .home-section {
    padding: 40px 20px 20px 20px;
  }
  .store-section {
    padding-top: 90px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 10%);
  }
  .section-title-img {
    height: 25px !important;
  }
  .section-title {
    font-size: 32px;
    margin-bottom: 50px;
  }
}
@media all and (max-width: 359px) {
  .home-section {
    padding: 40px 20px 20px 20px;
  }
  .store-section {
    padding-top: 70px;
  }
  .section-carousel,
  .store-section-carousel {
    height: calc(100vh - 10%);
  }
  .section-title-img {
    height: 25px !important;
  }
  .section-title {
    font-size: 27.2px;
    margin-bottom: 40px;
  }
}
