.form-btn-submit[disabled] {
  color: #333333;
}

/* 각 항목 Row별 간격 */
.service-form .ant-row:not(.ant-form-item-row) {
  margin-bottom: 30px;
}

.form-item-with-guideText {
  margin-bottom: 5px;
}

.contact-form-section .ant-form-item {
  margin-bottom: 40px;
}

/* Checkbox 색상 변경 start */
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: var(--point-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--point-color);
  color: white;
}
/* Checkbox 색상 변경 end */

/* Radio 버튼 색상 변경 start */
.ant-radio-checked .ant-radio-inner {
  border-color: var(--point-color) !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--point-color);
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--point-color);
}
/* Radio 버튼 색상 변경 end */

/* Input 스타일 변경 start */
.ant-input,
.ant-input-number,
.ant-select,
.ant-select-selector,
.ant-select-selection-search-input,
.ant-select-selection-item,
.ant-select-selection-placeholder {
  line-height: 40px !important;
  height: 40px !important;
}

.ant-select:hover,
.ant-input-number:hover,
.ant-input:hover,
.ant-input:focus {
  border-color: var(--point-color);
  box-shadow: 0 0 0 2px var(--point-shadow-color);
}

.ant-input:focus,
.ant-input-number:focus {
  box-shadow: 0 0 0 2px var(--point-shadow-color);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover,
.ant-select:not(.ant-select-customize-input) .ant-select-selector:active,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--point-color);
  box-shadow: 0 0 0 2px var(--point-shadow-color);
}

.contact-form-textarea {
  height: 134px !important;
}
/* Input 스타일 변경 end */

/* Font 변경 start */
.ant-radio-group > label,
.ant-checkbox-group > label,
.form-label,
.ant-select {
  font-size: 15px;
}
.ant-input,
.ant-input-number {
  font-size: 16px !important;
}
.ant-form-item-label > label {
  font-size: 18px;
}
/* Font 변경 end */

/* Submit Button 스타일 변경 start */
.form-submit-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.form-submit-btn {
  background-color: #333333;
  color: white;
  width: 100%;
  max-width: 250px;
  height: 62px;
  border-radius: 31px;
  border: none;
  font-size: 25px;
  font-weight: var(--pretendard-medium);
}

.form-btn-submit {
  background-color: #1d1b1c;
  color: white;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #1d1b1c;
  font-size: 20px;
  font-weight: var(--pretendard-semibold);
  margin-top: 12px;
}

.form-btn-submit:hover {
  background-color: var(--point-color);
  color: #fff !important;
  border: 1px solid var(--point-color) !important;
}

.form-btn-submit:focus {
  border: 1px solid white !important;
}

.form-label {
  font-size: 18px;
}

.form-label .essential {
  color: red;
  font-size: 18px;
  margin-right: 5px;
}

.form-guide-row {
  margin-bottom: 0 !important;
}

.form-guide {
  margin-bottom: 0;
}

/* Service Form (공통) start */
/* Modal 팝업 start */
.modal-title-icon {
  color: var(--point-color);
  font-size: 20px;
}

.modal-title-txt {
  margin-left: 10px;
  font-weight: var(--pretendard-semibold);
  font-size: 20px;
}

.modal-btn-naver,
.modal-ok-btn {
  /* background-color: var(--point-color); */
  background-color: var(--black-color);
  color: white;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
  font-weight: var(--pretendard-semibold);
  /* padding: 20px; */
  margin-right: 10px;
}

.form-modal > .ant-modal-content > .ant-modal-body {
  font-size: 15px;
}
/* Modal 팝업 end */

/* Service Form (공통) end */

/* Sell Watch Form start */
.service-form-section {
  margin-top: 80px;
}

.form-section-title {
  margin-bottom: 45px !important;
}

.refNo-form-item {
  margin-bottom: 15px;
}

.service-form-section .ant-row:not(.ant-form-item-row) {
  margin-bottom: 30px;
}

.refNo-form-col {
  margin-bottom: 15px;
}

.expected-price-txt {
  padding-left: 3px;
  color: #3c383a !important;
  font-size: 15px;
}

/* 애니메이션 버튼 start */
.btn-animated {
  text-align: center;
}

.btn-animated button {
  position: relative;
  display: inline-block;
  padding: 0 20px;
  border-radius: 31px;
  border: none;
  background-color: #858c92;
  color: #fff;
  font-size: 25px;
  font-weight: var(--pretendard-medium);
  text-align: center;
  cursor: pointer;
  width: 356px;
  height: 62px;
  /* transition: all 0.3s ease; */
}

.btn-icon-left {
  padding-right: 44px;
}
.btn-icon-right {
  padding-left: 44px;
}
/* 애니메이션 버튼 end */
/* Sell Watch Form end */

/* Trade Watch Form start */
.trade-watch .form-section-title {
  margin-top: 30px;
  margin-bottom: 24px;
}
/* Trade Watch Form end */

/* Bespoke Order Form start */
.bespoke-order .form-section-title {
  margin-top: 30px;
}
/* Bespoke Order Form end */

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1024px) {
} /* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 650px) and (max-width: 767px) {
}
@media all and (min-width: 560px) and (max-width: 650px) {
} /* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (min-width: 360px) and (max-width: 560px) {
  .ant-form-item-label > label {
    font-size: 16px;
  }
  .form-label {
    font-size: 14px;
  }
}
@media all and (max-width: 359px) {
  .ant-form-item-label > label {
    font-size: 16px;
  }
  .form-label {
    font-size: 14px;
  }
}
