.product-detail-section {
  --dotWidth: 80px;
  max-width: 1440px;
  height: 100%;
  margin: 80px auto;
  display: flex;
  column-gap: 100px;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 200px;
}

.product-detail__img {
  flex-basis: 65%;
  width: 100%;
  max-width: 650px;
  height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  position: relative;
}

.product-detail__img img {
  width: 100%;
  height: 100%;
}

.product-detail__img .on-left {
  left: 50px;
}

.product-detail__img .on-right {
  right: 50px;
}

.product-detail__img .icon-wrapper {
  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  /* width: 100%; */
  width: 80px;
  /* height: 100%; */
  height: 80px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
}

.product-detail__img .slider-item {
  position: relative;
}

.product-detail__img .slider-item iframe {
  width: 100%;
  max-width: 650px;
  height: auto;
  aspect-ratio: 1/1;
}

.product-detail__img .cpo-mark img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 40px;
  left: 40px;
}

.product-detail__img .product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 650px;
  max-height: 650px;
}

.product-detail__img .slick-dots {
  bottom: calc((var(--dotWidth) + 30px) * -1);
}

.product-detail__img .slick-dots li {
  width: var(--dotWidth);
  height: var(--dotWidth);
  margin: 0 20px;
}

.product-detail__details-container {
  flex-basis: 35%;
}

.product-detail__details {
  width: 100%;
  max-width: 359px;
  display: flex;
  flex-direction: column;
}

/* Deatils - Top Section start */
.product-detail__details .top-section .brand {
  font-size: 14px;
  margin-bottom: 15px;
}

.product-detail__details .top-section .model {
  font-size: 42px;
  margin-bottom: 11px;
  font-family: 'nimbus-roman', serif;
}

.product-detail__details .top-section .refNo {
  font-size: 16px;
  margin-bottom: 25px;
}

.product-detail__details .top-section .keywords {
  font-size: 14px;
  margin-bottom: 30px;
  font-weight: var(--pretendard-semibold);
}

.product-detail__details .top-section .keywords span {
  margin-left: 20px;
  margin-right: 20px;
}

.product-detail__details .top-section .description {
  font-size: 14px;
  margin-bottom: 30px;
}

.product-detail__details .top-section .more {
  width: fit-content;
  font-size: 14px;
  color: #666666;
  margin-bottom: 40px;
  border-bottom: 1px solid #666666;
}

.product-detail__details .top-section .bluement-guaranteed {
  display: flex;
  column-gap: 40px;

  margin-bottom: 30px;
}

.product-detail__details .top-section .price {
  font-size: 20px;
  font-weight: var(--pretendard-bold);

  margin-bottom: 40px;
}

.product-detail__details .top-section .bluement-guaranteed .item {
  width: fit-content;

  column-gap: 5px;
  align-items: center;
}

.product-detail__details .top-section .bluement-guaranteed .item .text {
  font-size: 14px;
  font-weight: var(--pretendard-bold);
}

.product-detail__details .top-section .square-btn-lg {
  margin-bottom: 50px;
}

/* Deatils - Top Section end */

.product-detail__details .divider {
  border-top: 0.5px solid #aaaaaa;
  margin: 0;
}

/* Deatils - Bottom Section start */
.product-detail__details .bottom-section {
  width: 100%;
  height: 100%;
}

.product-detail__details .bottom-section .important-info {
  margin-top: 40px;
}

.ant-collapse-borderless {
  background: transparent;
}

.product-detail-section .ant-collapse-header {
  height: 74px;
  line-height: 74px !important;
  padding: 0 16px 0 14px !important;
}

.product-detail-section .ant-collapse-expand-icon svg {
  width: 14px;
  height: 16px;
}

.product-detail-section .ant-collapse-content {
  font-size: 14px;
  font-weight: 300;
}

.product-detail-section .ant-collapse-content > .ant-collapse-content-box {
  padding-bottom: 30px;
}

.product-detail-section
  .ant-collapse-content
  > .ant-collapse-content-box
  div
  div {
  line-height: 2.5;
}

.product-detail-section .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0.5px solid #aaaaaa !important;
}
/* Details - Bottom Section end */

/* Responsive */
@media screen and (max-width: 1200px) {
  .product-detail-section {
    max-width: 1000px;
    column-gap: 50px;
  }

  .product-detail__img {
    flex-basis: 55%;
  }

  .product-detail__img .cpo-mark img {
    width: 90px;
    height: 90px;
  }

  .product-detail__details-container {
    flex-basis: 45%;
  }
}

@media screen and (max-width: 1070.98px) {
  .product-detail-section {
    margin: 60px auto;
    margin-bottom: 100px;
    flex-direction: column;
    align-items: center;
  }

  .product-detail__img {
    flex-basis: 100%;
    margin-bottom: calc(var(--dotWidth) + 70px);
  }

  .product-detail__details-container {
    flex-basis: 100%;
  }

  .product-detail__details {
    max-width: 650px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .product-detail__img img {
    width: 100%;
    height: 100%;
    margin-right: 0;
  }

  /* .product-detail__img .cpo-mark img {
    width: 70px;
    height: 70px;
    top: 20px;
    left: 20px;
  } */

  /* Details */
  /* .product-detail__details .top-section .brand {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .product-detail__details .top-section .model {
    font-size: 38px;
    margin-bottom: 8px;
  }

  .product-detail__details .top-section .refNo {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .product-detail__details .top-section .keywords {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .product-detail__details .top-section .keywords span {
    margin-left: 15px;
    margin-right: 15px;
  }

  .product-detail__details .top-section .description {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .product-detail__details .top-section .more {
    font-size: 14px;
  } */
}

@media screen and (max-width: 880.98px) {
  .product-detail-section {
    max-width: 100%;
    padding: 0 60px;
  }

  .product-detail__img .on-right {
    right: 5%;
  }

  .product-detail__img .on-left {
    left: 5%;
  }

  /* .product-detail__img img {
    width: 130%;
    height: 130%;
    margin-right: 10px;
  } */

  /* .product-detail__img .cpo-mark img {
    width: 60px;
    height: 60px;
    top: 10px;
    left: 10px;
  } */

  /* Details */
  /* .product-detail__details .top-section .brand {
    font-size: 12px;
    margin-bottom: 7px;
  }

  .product-detail__details .top-section .model {
    font-size: 34px;
    margin-bottom: 8px;
  }

  .product-detail__details .top-section .refNo {
    font-size: 14px;
    margin-bottom: 17px;
  }

  .product-detail__details .top-section .keywords {
    font-size: 13px;
    margin-bottom: 22px;
  }

  .product-detail__details .top-section .keywords span {
    margin-left: 13px;
    margin-right: 13px;
  }

  .product-detail__details .top-section .description {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .product-detail__details .top-section .more {
    font-size: 13px;
  }

  .product-detail__details .top-section .square-btn-lg {
    margin-bottom: 40px;
  } */

  /* Deatils - Bottom Section start */
  /* .product-detail-section .ant-collapse-header {
    height: 70px;
    line-height: 70px !important;
  }

  .product-detail-section .ant-collapse-content {
    font-size: 13px;
  }

  .product-detail-section .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 26px;
  }

  .product-detail-section
    .ant-collapse-content
    > .ant-collapse-content-box
    div
    div {
    line-height: 2.3;
  } */
}

@media screen and (min-width: 577px) and (max-width: 675.98px) {
  .product-detail-section {
    padding: 0 40px;
    margin: 60px auto;
    margin-bottom: 150px;
  }

  /* .product-detail__img img {
    width: 130%;
    height: 130%;
    margin-right: 10px;
  } */

  /* .product-detail__img .cpo-mark img {
    width: 60px;
    height: 60px;
    top: 0;
    left: 0;
  } */

  /* Details */
  /* .product-detail__details .top-section .model {
    font-size: 30px;
    margin-bottom: 8px;
  }

  .product-detail__details .top-section .refNo {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .product-detail__details .top-section .keywords {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .product-detail__details .top-section .description {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .product-detail__details .top-section .more {
    font-size: 13px;
  }

  .product-detail__details .top-section .square-btn-lg {
    margin-bottom: 40px;
  }

  .square-btn-lg button {
    font-size: 14px;
  } */

  /* Deatils - Bottom Section start */
  /* .product-detail-section .ant-collapse-header {
    height: 60px;
    line-height: 60px !important;
  }

  .product-detail-section .ant-collapse-content {
    font-size: 12px;
  } */
}

@media screen and (max-width: 576.98px) {
  .product-detail-section {
    margin: 60px auto;
    margin-bottom: 100px;
    flex-direction: column;
    align-items: center;
  }

  .product-detail__details {
    padding-left: 0;
    padding-right: 0;
  }

  .product-detail__img .cpo-mark img {
    width: 80px;
    height: 80px;
    top: 20px;
    left: 20px;
  }

  /* Deatils - Top Section start */
  .product-detail__details .top-section .brand {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .product-detail__details .top-section .model {
    font-size: 42px;
    margin-bottom: 11px;
  }

  .product-detail__details .top-section .refNo {
    font-size: 16px;
    margin-bottom: 25px;
  }

  .product-detail__details .top-section .keywords {
    font-size: 14px;
    margin-bottom: 30px;
  }

  .product-detail__details .top-section .keywords span {
    margin-left: 20px;
    margin-right: 20px;
  }

  .product-detail__details .top-section .description {
    font-size: 14px;
    margin-bottom: 40px;
  }

  .product-detail__details .top-section .more {
    font-size: 14px;
  }

  .product-detail__details .top-section .square-btn-lg {
    margin-bottom: 50px;
  }

  /* Deatils - Top Section end */

  /* Deatils - Bottom Section start */
  .product-detail-section .ant-collapse-header {
    height: 74px;
    line-height: 74px !important;
  }

  .product-detail-section .ant-collapse-content {
    font-size: 14px;
  }

  .product-detail-section .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 30px;
  }

  .product-detail-section
    .ant-collapse-content
    > .ant-collapse-content-box
    div
    div {
    line-height: 2.5;
  }
  /* Details - Bottom Section end */
}

@media screen and (max-width: 454.98px) {
  .product-detail-section {
    --dotWidth: 60px;
    --arrowWidth: 50px;
    margin: 40px auto;
    margin-bottom: 100px;
  }

  .product-detail__img .icon-wrapper,
  .product-detail__img .icon-wrapper svg {
    width: var(--arrowWidth);
    height: var(--arrowWidth);
  }

  .product-detail__img .on-right {
    right: calc(-1 * var(--arrowWidth));
  }

  .product-detail__img .on-left {
    left: calc(-1 * var(--arrowWidth));
  }

  .product-detail__img .cpo-mark img {
    width: 60px;
    height: 60px;
    top: 0;
    left: 0;
  }

  /* Deatils - Top Section start */
  .product-detail__details .top-section .brand {
    margin-bottom: 10px;
  }

  .product-detail__details .top-section .model {
    font-size: 35px;
    margin-bottom: 9px;
  }

  .product-detail__details .top-section .refNo {
    margin-bottom: 20px;
  }

  .product-detail__details .top-section .keywords {
    margin-bottom: 25px;
  }

  .product-detail__details .top-section .keywords span {
    margin-left: 15px;
    margin-right: 15px;
  }

  .product-detail__details .top-section .description {
    margin-bottom: 30px;
  }

  .product-detail__details .top-section .square-btn-lg {
    margin-bottom: 40px;
  }

  /* Deatils - Top Section end */

  /* Deatils - Bottom Section start */
  .product-detail-section .ant-collapse-header {
    height: 70px;
    line-height: 70px !important;
  }

  .product-detail-section .ant-collapse-content {
    font-size: 14px;
  }

  .product-detail-section .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 25px;
  }

  .product-detail-section
    .ant-collapse-content
    > .ant-collapse-content-box
    div
    div {
    line-height: 2.3;
  }
  /* Details - Bottom Section end */
}

@media screen and (max-width: 428px) {
  .product-detail__details .top-section .bluement-guaranteed {
    column-gap: 20px;
  }
}

@media screen and (max-width: 376.98px) {
  .product-detail-section {
    margin: 30px auto;
    margin-bottom: 100px;
    padding: 0 50px;
  }

  /* Deatils - Top Section start */
  .product-detail__details .top-section .brand {
    margin-bottom: 7px;
  }

  .product-detail__details .top-section .model {
    font-size: 30px;
    margin-bottom: 5px;
  }

  .product-detail__details .top-section .refNo {
    margin-bottom: 15px;
  }

  .product-detail__details .top-section .keywords {
    font-size: 13px;
    margin-bottom: 20px;
  }

  .product-detail__details .top-section .keywords span {
    margin-left: 13px;
    margin-right: 13px;
  }

  .product-detail__details .top-section .description {
    margin-bottom: 30px;
  }

  .product-detail__details .top-section .bluement-guaranteed {
    column-gap: 17px;
  }

  .product-detail__details .top-section .square-btn-lg {
    margin-bottom: 40px;
  }

  /* Deatils - Top Section end */

  /* Deatils - Bottom Section start */
  .product-detail-section .ant-collapse-header {
    height: 70px;
    line-height: 70px !important;
  }

  .product-detail-section .ant-collapse-content {
    font-size: 14px;
  }

  .product-detail-section .ant-collapse-content > .ant-collapse-content-box {
    padding-bottom: 25px;
  }

  .product-detail-section
    .ant-collapse-content
    > .ant-collapse-content-box
    div
    div {
    font-size: 13px;
    line-height: 2.3;
  }
  /* Details - Bottom Section end */
}
