.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fff;
  /* padding: 20px 0; */
  padding-bottom: 80px;
  max-width: 300px;
  margin: 0 auto;
  cursor: pointer;
}

.product-card-centered {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fff;
  /* padding: 20px 0; */
  padding-bottom: 80px;
  max-width: 300px;
  margin: 0 auto;
}

.product-card img,
.product-card picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 300px;
}

.product-card picture {
  margin-bottom: 20px;
}

.product-card-deatils {
  padding-left: 20px;
  padding-right: 15px;
}

.product-card-deatils-centered {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.product-card-deatils__top {
  font-size: 12px;
}

.product-card-deatils__bottom {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  align-items: center;
}

.product-card-deatils__bottom.price {
  margin-top: 6px;
}

.product-card-deatils__bottom .refNo {
  font-size: 14px;
}

.product-card-deatils__bottom .year {
  color: #aaaaaa;
}

.product-card-deatils__bottom .price {
  font-size: 15px;
  font-weight: var(--pretendard-semibold);
}

.product-card-deatils__top span {
  margin-left: 12px;
  margin-right: 12px;
}

.product-card-deatils h2,
.product-card-deatils-centered h2 {
  /* font-family: 'Lora', serif !important; */
  font-family: 'nimbus-roman', serif;
  font-weight: 500;
  font-size: 34px;
}

.product-card-deatils p,
.product-card-deatils h2,
.product-card-deatils-centered p,
.product-card-deatils-centered h2 {
  margin-bottom: 0px;
}

.product-card-deatils-centered p,
.product-card-deatils-centered h2 {
  margin-bottom: 0px;
}

@media all and (min-width: 1920px) {
}
@media all and (min-width: 1472px) and (max-width: 1919.98px) {
  .product-card h2,
  .product-card-centered h2 {
    font-size: 30px;
  }
}

@media all and (min-width: 1380px) and (max-width: 1471.98px) {
  .product-card h2,
  .product-card-centered h2 {
    font-size: 27px;
  }
}

@media all and (min-width: 1180px) and (max-width: 1379.98px) {
  .product-card h2,
  .product-card-centered h2 {
    font-size: 24px;
  }
}

@media all and (min-width: 1024px) and (max-width: 1179.98px) {
  .product-card h2,
  .product-card-centered h2 {
    font-size: 20px;
  }
  .product-card-deatils__top span {
    margin-left: 8px;
    margin-right: 8px;
  }
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/ /* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023.98px) {
  .product-card {
    padding-bottom: 60px;
  }

  .product-card-deatils,
  .product-card-deatils-centered {
    padding-left: 15px;
    padding-right: 15px;
  }

  .product-card h2,
  .product-card-centered h2 {
    font-size: 20px;
  }

  .product-card-deatils__top span {
    margin-left: 6px;
    margin-right: 6px;
  }
}

@media all and (min-width: 650px) and (max-width: 767px) {
  .product-card {
    padding-bottom: 50px;
  }

  .product-card-deatils,
  .product-card-deatils-centered {
    padding-left: 15px;
    padding-right: 15px;
  }

  .product-card h2,
  .product-card-centered h2 {
    font-size: 17px;
  }

  .product-card-deatils__top span {
    margin-left: 6px;
    margin-right: 6px;
  }
}
@media all and (min-width: 560px) and (max-width: 650px) {
  .product-card {
    padding-bottom: 50px;
  }

  .product-card-deatils,
  .product-card-deatils-centered {
    padding-left: 15px;
    padding-right: 15px;
  }

  .product-card h2,
  .product-card-centered h2 {
    font-size: 15px;
  }

  .product-card-deatils__top span {
    margin-left: 6px;
    margin-right: 6px;
  }

  .product-card-deatils__bottom .refNo {
    font-size: 12px;
  }
}

@media all and (min-width: 460px) and (max-width: 559.98px) {
  .collection-section-layout {
    padding-bottom: 30px;
  }
  .product-card {
    padding-bottom: 40px;
  }

  .product-card-deatils,
  .product-card-deatils-centered {
    padding-left: 15px;
    padding-right: 15px;
  }

  .product-card h2,
  .product-card-centered h2 {
    font-size: 20px;
  }

  .product-card-deatils__top span {
    margin-left: 6px;
    margin-right: 6px;
  }

  .product-card-deatils__bottom .refNo {
    font-size: 11px;
  }
}

@media all and (min-width: 385px) and (max-width: 459.98px) {
  .product-card-deatils__bottom .price {
    font-size: 13px;
  }

  .collection-section-layout {
    padding-bottom: 30px;
  }
  .product-card {
    padding-bottom: 40px;
  }

  .product-card picture {
    margin-bottom: 15px;
  }

  .product-card-deatils,
  .product-card-deatils-centered {
    padding-left: 15px;
  }

  .product-card h2,
  .product-card-centered h2 {
    font-size: 16px;
  }

  .product-card-deatils__top span {
    margin-left: 6px;
    margin-right: 6px;
  }

  .product-card-deatils__bottom .refNo {
    font-size: 11px;
  }
}
@media all and (max-width: 384.98px) {
  .collection-section-layout {
    padding-bottom: 20px;
  }
  .product-card {
    padding-bottom: 30px;
  }

  .product-card picture {
    margin-bottom: 15px;
  }

  .product-card-deatils,
  .product-card-deatils-centered {
    padding-left: 10px;
    padding-right: 10px;
  }

  .product-card-deatils p,
  .product-card-deatils h2,
  .product-card-deatils-centered p,
  .product-card-deatils-centered h2 {
    margin-bottom: 1px;
  }

  .product-card-deatils__top {
    font-size: 11px;
  }

  .product-card h2,
  .product-card-centered h2 {
    font-size: 16px;
  }

  .product-card-deatils__top span {
    margin-left: 5px;
    margin-right: 5px;
  }

  .product-card-deatils__bottom .refNo {
    font-size: 11px;
  }

  .product-card-deatils__bottom .price {
    font-size: 13px;
  }
}
